import { StateCreator } from "zustand";

import { sliceResetFns } from "..";

export type ItemInvoice = {
  filename: string;
  end_date: number;
  month: string;
  year: string;
  tenant_id: string;
  tenant_ownerid: string;
  download_timestamp: string;
  mail_send_timestamp: string;
};

export type UserDetailSlice = {
  billingUserInfo: {
    is_corporation?: boolean;
    company_name: string;
    department: string;
    position: string;
    surname: string;
    surname_ruby: string;
    name: string;
    name_ruby: string;
    post_code: string;
    state: string;
    address_line1: string;
    address_line2: string;
    mail_address: string;
    phone_number: string;
  };
  user: {
    user_id: string;
    email: string;
  };
  invoiceList: ItemInvoice[];
  setBillingInfoUserData: (data) => void;
  setInvoiceList: (data) => void;
  setUser: (data) => void;
  resetBillingInfoUser: () => void;
};

export const initialState = {
  billingUserInfo: {
    company_name: "",
    department: "",
    position: "",
    surname: "",
    surname_ruby: "",
    name: "",
    name_ruby: "",
    post_code: "",
    state: "",
    address_line1: "",
    address_line2: "",
    mail_address: "",
    phone_number: "",
  },
  invoiceList: [
    {
      filename: "",
      end_date: 0,
      month: "",
      year: "",
      tenant_id: "",
      tenant_ownerid: "",
      download_timestamp: "",
      mail_send_timestamp: "",
    },
  ],
  user: {
    user_id: "",
    email: "",
  },
};

export const userDetailSlice: StateCreator<UserDetailSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setBillingInfoUserData: (data) => {
      set(() => ({ billingUserInfo: data }));
    },
    setInvoiceList: (data) => {
      set({ invoiceList: data });
    },
    setUser: (data) => {
      set({ user: data });
    },
    resetBillingInfoUser: () => {
      set({ billingUserInfo: initialState.billingUserInfo });
    },
  };
};
