import { StateCreator } from "zustand";
import { decryptData, encryptData } from "../../utils/helper";
import { sliceResetFns } from "..";

// This slice is used to access and modify the email attribute of
// the encrypted data in the local storage.

export type EmailSlice = {
  email: string;
  setEmail: (newEmail: string) => void;
};

const key = "atmark-encryptedUserData";

const initialState = {
  email: (function () {
    try {
      const data = JSON.parse(decryptData(localStorage.getItem(key)) ?? "{}");
      return data.email ?? "";
    } catch (error) {
      console.log(error);
      return "";
    }
  })(),
};

export const createEmailSlice: StateCreator<EmailSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setEmail: (newEmail) => {
      const data = JSON.parse(decryptData(localStorage.getItem(key)) ?? "{}");
      data.email = newEmail;
      const encryptedUserData = encryptData(data);
      localStorage.setItem(key, encryptedUserData);
      set(() => ({ email: newEmail }));
    },
  };
};
