import { Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Forbidden = () => {
  const { t } = useTranslation();
  return (
    <main className="flex h-screen w-screen items-center justify-center">
      <div className="px-4 py-5 text-center">
        <h1 className="text-[192px] font-bold leading-none text-primary">403</h1>
        <h2 className="text-2xl font-semibold tracking-wide">Access denied</h2>
        <div className="mb-4 mt-4 max-w-lg text-center font-normal">{t("NOT_ACCESS_PERMISSION_403")}</div>
        <div className="flex items-center justify-center gap-4">
          <Link to={"/"}>
            <Button variation="primary">{t("BACK_TO_HOME")}</Button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Forbidden;
