import { StateCreator } from "zustand";
import { updateLanguageUrl } from "../../utils/updateLanguageUrl";

export type LanguageSlice = {
  language: string;
  languageDisplayed: string;
  setLanguage: (newLang: string) => void;
};

export const createLanguageSlice: StateCreator<LanguageSlice> = (set) => ({
  language: localStorage.getItem("atmark-lng") ?? navigator.language.slice(0, 2),
  languageDisplayed: localStorage.getItem("atmark-lng_display") ?? navigator.language.slice(0, 2),
  setLanguage: (newLang) => {
    const checkInput = (function () {
      if (newLang === "DEFAULT") {
        return navigator.language.startsWith("en") ? "en" : "ja";
      }
      return newLang;
    })();
    localStorage.setItem("atmark-lng", checkInput);
    localStorage.setItem("atmark-lng_display", newLang);
    updateLanguageUrl(checkInput);
    set(() => ({ language: newLang, languageDisplayed: newLang }));
  },
});
