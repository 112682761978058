import i18next, { changeLanguage, t } from "i18next";
import { calculateMatchingPercentage } from "./CalculateStringPercentage";

export const updateLanguageUrl = (nextLanguage) => {
  const routes = i18next.getResourceBundle(i18next.language, "routes");
  const currentPathname = window.location.pathname.replace(/\/+$/, "");
  const languageTag = currentPathname.split("/")[1];
  const currentRouteKey = Object.keys(routes).find((key) => routes[key] === currentPathname);

  if (!currentRouteKey) {
    let maxMatchingPercentage = 0;
    let matchedRoute: unknown = null;

    Object.values(routes).forEach((route) => {
      const matchingPercentage = calculateMatchingPercentage(currentPathname, route);
      if (matchingPercentage > maxMatchingPercentage) {
        maxMatchingPercentage = matchingPercentage;
        matchedRoute = route;
      }
    });

    if (matchedRoute) {
      const newRoute = currentPathname.replace(`/${languageTag}/`, `/${nextLanguage}/`);
      changeLanguage(nextLanguage);
      window.history.replaceState(null, "", newRoute);
    }
    return;
  }
  const newRoute = t(`routes:${currentRouteKey}`, { lng: nextLanguage });
  const newURL = `${window.location.origin}${newRoute || currentPathname}`;
  changeLanguage(nextLanguage);
  window.history.replaceState(null, "", newURL);
};
