export interface IStepProps {
  setActiveStep: (step: number) => void;
}

export enum BasicDataEnum {
  USAGE_PLAN = "usagePlan",
  START_TYPE = "startType",
  CONTRACTOR_TYPE = "contractorType",

  USAGE_PLAN_TRIAL = "usage_plan_trial",
  USAGE_PLAN_CONTRACT = "usage_plan_contact",
  START_TYPE_IMMEDIATE = "start_type_immediate",
  START_TYPE_NEXT_MONTH = "start_type_next_month",
  CONTRACTOR_TYPE_PERSONAL = "contractor_type_personal",
  CONTRACTOR_TYPE_CORPORATION = "contractor_type_corporation",
}
