import { StateCreator } from "zustand";
import { sliceResetFns } from "..";

export type EncryptedKeySlice = {
  encryptedKey: string;
  newEmail: string;
  setEncryptedKey: (key: string) => void;
  setNewEmail: (email: string) => void;
  resetEncryptedKeySlice: () => void;
};

const initialState = {
  encryptedKey: "",
  newEmail: "",
};

export const createEncryptedKeySlice: StateCreator<EncryptedKeySlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setEncryptedKey: (newKey) => {
      set(() => ({ encryptedKey: newKey }));
    },
    setNewEmail: (email) => {
      set(() => ({ newEmail: email }));
    },
    resetEncryptedKeySlice: () => set(initialState),
  };
};
