import yup from "@/locales/validation";
import { IStepProps, BasicDataEnum } from "../SignUp.type";
import { Button, Card, Link, PasswordField, SelectField, TextField } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmailRegex } from "@/constants/EmailRegex";
import CustomErrorMessage from "@/components/ui/CustomErrorMessage/CustomErrorMessage";
import useBoundStore from "@/stores";
import { FieldWrapper } from "./FieldWrapper/FieldWrapper";
import { Prefectures } from "@/constants/Prefectures";
import AmplifyCheckmark from "@/components/ui/Icon/AmplifyCheckmark";

const Step2 = ({ setActiveStep }: IStepProps) => {
  const { t } = useTranslation();

  const [resetCreateAccountSlice, setContractorData, contractorData, basicData, setBasicData] = useBoundStore(
    (state) => [
      state.resetCreateAccountSlice,
      state.setContractorData,
      state.contractorData,
      state.basicData,
      state.setBasicData,
    ],
  );

  const schema = yup.object({
    username: yup
      .string()
      .required("CommonError.FieldRequired")
      .matches(/^(?!\s*$).+/, "CommonError.FieldRequired"),
    email: yup.string().required("CommonError.FieldRequired").matches(EmailRegex, "CommonError.FieldFormat"),
    password: yup
      .string()
      .required("CommonError.FieldRequired")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\])[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\A-Za-z\d]{8,99}$/,
        "CommonError.PasswordInvalid",
      ),
    confirmPassword: yup
      .string()
      .required("CommonError.FieldRequired")
      .test("password-match", "CommonError.ConfirmPasswordNotMatch", function (value, context) {
        if (!value) {
          return context.createError({ message: "CommonError.FieldRequired" });
        }
        if (value !== context.parent.password) {
          return context.createError({ message: "CommonError.ConfirmPasswordNotMatch" });
        }
        return true;
      }),
    company_name: yup.string().trim().required("CommonError.FieldRequired"),
    department: yup.string().trim(),
    position: yup.string().trim(),
    surname: yup.string().trim().required("CommonError.FieldRequired"),
    surname_ruby: yup.string().trim().required("CommonError.FieldRequired"),
    name: yup.string().trim().required("CommonError.FieldRequired"),
    name_ruby: yup.string().trim().required("CommonError.FieldRequired"),
    post_code_part1: yup.string().trim().required("CommonError.FieldRequired"),
    post_code_part2: yup.string().trim().required("CommonError.FieldRequired"),
    state: yup.string().trim().required("CommonError.FieldRequired"),
    address_line1: yup.string().trim().required("CommonError.FieldRequired"),
    address_line2: yup.string().trim(),
    phone_number_part1: yup.string().trim().required("CommonError.FieldRequired").min(1, "CommonError.FieldRequired"),
    phone_number_part2: yup.string().trim().required("CommonError.FieldRequired").min(1, "CommonError.FieldRequired"),
    phone_number_part3: yup.string().trim().required("CommonError.FieldRequired").min(1, "CommonError.FieldRequired"),
    acceptToS: yup.boolean().required().default(false),
  });

  const {
    handleSubmit,
    register,
    watch,
    getFieldState,
    getValues,
    setValue,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: contractorData,
  });

  const onFormSubmit = (data) => {
    setContractorData(data);
    setActiveStep(2);
  };

  useEffect(() => {
    if (basicData.contractorType !== BasicDataEnum.CONTRACTOR_TYPE_CORPORATION) {
      setValue("company_name", "default");
    }
  }, []);

  return (
    <Card variation="outlined">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="p-2.5">
        <div className=" flex flex-col gap-4">
          <FieldWrapper
            label={t("User.Username")}
            isRequired>
            <TextField
              className="!relative"
              {...register("username")}
              label={""}
              labelHidden
              hasError={Boolean(errors.username)}
              errorMessage={String(t(`${errors.username?.message}`, { field: t("User.Username") }))}
              data-testid="user-input"
            />
          </FieldWrapper>
          <FieldWrapper
            label={t("User.EmailAddress")}
            isRequired>
            <TextField
              className="!relative"
              {...register("email")}
              label={""}
              labelHidden
              hasError={Boolean(errors.email)}
              errorMessage={String(t(`${errors.email?.message}`, { field: t("User.EmailAddress") }))}
              data-testid="email-input"
            />
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.Password")}
            isRequired>
            <PasswordField
              className="!relative"
              {...register("password", {
                // Revalidate the confirm password field when the password field changes.
                onChange() {
                  if (getValues("confirmPassword")) {
                    trigger("confirmPassword");
                  }
                },
              })}
              onKeyDown={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              label=""
              labelHidden
              hasError={Boolean(errors.password)}
              errorMessage={
                errors.password?.message === "CommonError.FieldRequired"
                  ? String(t("CommonError.FieldRequired", { field: t("User.Password") }))
                  : ""
              }
              hideShowPassword={true}
              data-testid="password-input"
            />
            {getFieldState("password").isDirty && (
              <CustomErrorMessage
                className="mt-2"
                data={watch("password")}
              />
            )}
          </FieldWrapper>

          <FieldWrapper
            label={t("UserAuth06.Step2.ConfirmPassword")}
            isRequired>
            <PasswordField
              className="!relative"
              {...register("confirmPassword")}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onKeyDown={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              label=""
              labelHidden
              hasError={Boolean(errors.confirmPassword)}
              errorMessage={String(t(`${errors.confirmPassword?.message}`, { field: t("ConfirmPassword") }))}
              hideShowPassword={true}
              data-testid="confirem-password-input"></PasswordField>
          </FieldWrapper>
          {basicData.contractorType === BasicDataEnum.CONTRACTOR_TYPE_CORPORATION && (
            <>
              <FieldWrapper
                label={t("UserAuth06.Step2.CompanyName")}
                isRequired>
                <TextField
                  className="!relative"
                  {...register("company_name")}
                  label=""
                  labelHidden
                  hasError={Boolean(errors.company_name)}
                  errorMessage={String(
                    t(`${errors.company_name?.message}`, { field: t("UserAuth06.Step2.CompanyName") }),
                  )}
                  data-testid="company-name-input"
                />
              </FieldWrapper>

              <FieldWrapper label={t("UserAuth06.Step2.Department")}>
                <TextField
                  className="!relative"
                  {...register("department")}
                  label=""
                  labelHidden
                  hasError={Boolean(errors.department)}
                  errorMessage={String(t(`${errors.department?.message}`, { field: t("UserAuth06.Step2.Department") }))}
                  data-testid="department-input"
                />
              </FieldWrapper>
              <FieldWrapper label={t("UserAuth06.Step2.Position")}>
                <TextField
                  {...register("position")}
                  className="!relative !col-span-2"
                  label=""
                  labelHidden
                  hasError={Boolean(errors.position)}
                  errorMessage={String(t(`${errors.position?.message}`, { field: t("UserAuth06.Step2.Position") }))}
                  data-testid="position-input"
                />
              </FieldWrapper>
            </>
          )}
          <FieldWrapper
            label={t("UserAuth06.Step2.FullName")}
            isRequired>
            <div className="col-span-2 flex items-start gap-4">
              <TextField
                {...register("surname")}
                className="!relative !flex-1"
                label=""
                labelHidden
                hasError={Boolean(errors.surname)}
                errorMessage={String(t(`${errors.surname?.message}`, { field: t("UserAuth06.Step2.Surname") }))}
                data-testid="surname-input"
              />
              <TextField
                {...register("name")}
                className="!relative !flex-1"
                label=""
                labelHidden
                hasError={Boolean(errors.name)}
                errorMessage={String(t(`${errors.name?.message}`, { field: t("UserAuth06.Step2.Name") }))}
                data-testid="name-input"
              />
            </div>
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.FullNameTrans")}
            isRequired>
            <div className="flex items-start gap-4">
              <TextField
                {...register("surname_ruby")}
                className="!relative !flex-1"
                label=""
                labelHidden
                hasError={Boolean(errors.surname_ruby)}
                errorMessage={String(
                  t(`${errors.surname_ruby?.message}`, { field: t("UserAuth06.Step2.SurnameTrans") }),
                )}
                data-testid="surname-ruby-input"
              />
              <TextField
                {...register("name_ruby")}
                className="!relative !flex-1"
                label=""
                labelHidden
                hasError={Boolean(errors.name_ruby)}
                errorMessage={String(t(`${errors.name_ruby?.message}`, { field: t("UserAuth06.Step2.NameTrans") }))}
                data-testid="name-ruby-input"
              />
            </div>
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.PostalCode")}
            isRequired>
            <div className="mr-[12.4px] grid  grid-cols-3 gap-4">
              <div className="col-span-2 flex items-start gap-4">
                <TextField
                  className="!relative"
                  {...register("post_code_part1", {
                    onChange(e) {
                      e.target.value = String(e.target.value).replace(/\D/g, "");
                    },
                  })}
                  label=""
                  labelHidden
                  hasError={Boolean(errors.post_code_part1)}
                  errorMessage={String(
                    t(`${errors.post_code_part1?.message}`, { field: t("UserAuth06.Step2.PostalCode") }),
                  )}
                  data-testid="post-code-part1-input"
                />
                <span className="mt-[9px]">-</span>
                <TextField
                  className="!relative"
                  {...register("post_code_part2", {
                    onChange(e) {
                      e.target.value = String(e.target.value).replace(/\D/g, "");
                    },
                  })}
                  label=""
                  labelHidden
                  hasError={Boolean(errors.post_code_part2)}
                  errorMessage={String(
                    t(`${errors.post_code_part2?.message}`, { field: t("UserAuth06.Step2.PostalCode") }),
                  )}
                  data-testid="post-code-part2-input"
                />
              </div>
            </div>
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.Prefecture")}
            isRequired>
            <div className="mr-[12.4px] grid grid-cols-3 gap-4">
              <div className="col-span-2 flex">
                <SelectField
                  {...register("state")}
                  className="!relative !grow"
                  label=""
                  labelHidden
                  hasError={Boolean(errors.state)}
                  data-testid="prefectures-select">
                  {Object.keys(Prefectures).map((key) => (
                    <option
                      value={key}
                      key={key}>
                      {key}
                    </option>
                  ))}
                </SelectField>
              </div>
            </div>
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.AddressLine1")}
            isRequired>
            <TextField
              className="!relative"
              {...register("address_line1")}
              label=""
              labelHidden
              hasError={Boolean(errors.address_line1)}
              errorMessage={String(
                t(`${errors.address_line1?.message}`, { field: t("UserAuth06.Step2.AddressLine1") }),
              )}
              data-testid="address-line1-input"
            />
          </FieldWrapper>
          <FieldWrapper label={t("UserAuth06.Step2.AddressLine2")}>
            <TextField
              className="!relative"
              {...register("address_line2")}
              label=""
              labelHidden
              hasError={Boolean(errors.address_line2)}
              data-testid="address-line2-input"
            />
          </FieldWrapper>
          <FieldWrapper
            label={t("UserAuth06.Step2.PhoneNumber")}
            isRequired>
            <div className="flex items-start gap-4">
              <TextField
                className="!relative"
                {...register("phone_number_part1", {
                  onChange(e) {
                    e.target.value = String(e.target.value).replace(/\D/g, "");
                  },
                })}
                label=""
                labelHidden
                hasError={Boolean(errors.phone_number_part1)}
                errorMessage={String(
                  t(`${errors.phone_number_part1?.message}`, { field: t("UserAuth06.Step2.PhoneNumber") }),
                )}
                data-testid="phone-number-part1-input"
              />
              <span className="mt-[9px]">-</span>
              <TextField
                className="!relative"
                {...register("phone_number_part2", {
                  onChange(e) {
                    e.target.value = String(e.target.value).replace(/\D/g, "");
                  },
                })}
                label=""
                labelHidden
                hasError={Boolean(errors.phone_number_part2)}
                errorMessage={String(
                  t(`${errors.phone_number_part2?.message}`, { field: t("UserAuth06.Step2.PhoneNumber") }),
                )}
                data-testid="phone-number-part2-input"
              />
              <span className="mt-[9px]">-</span>
              <TextField
                className="!relative"
                {...register("phone_number_part3", {
                  onChange(e) {
                    e.target.value = String(e.target.value).replace(/\D/g, "");
                  },
                })}
                label=""
                labelHidden
                hasError={Boolean(errors.phone_number_part3)}
                errorMessage={String(
                  t(`${errors.phone_number_part3?.message}`, { field: t("UserAuth06.Step2.PhoneNumber") }),
                )}
                data-testid="phone-number-part3-input"
              />
            </div>
          </FieldWrapper>
          <div className="flex items-center gap-2">
            <div className="custom-checkbox relative h-4 w-4 overflow-hidden rounded-[20%] border-2">
              <input
                id="acceptToS"
                className="absolute -left-2.5 -top-2.5 z-[2] h-8 w-8 cursor-pointer opacity-0"
                type="checkbox"
                {...register("acceptToS")}
                data-testid="acceptToS-checkbox"
              />
              <span className="checkmark absolute left-0 top-0 flex h-full w-full items-center justify-center opacity-0">
                <AmplifyCheckmark />
              </span>
            </div>
            <label
              htmlFor="acceptToS"
              className="cursor-pointer whitespace-pre-line text-left">
              <Trans i18nKey={"P&S"}>
                I accept the
                <Link
                  href="https://www.atmark-techno.com/privacy"
                  target="_blank">
                  Privacy Policy
                </Link>
                and
                <Link
                  href="https://download.atmark-techno.com/armadillo-twin/document/armadillo-twin-terms.pdf"
                  target="_blank">
                  Terms of Service
                </Link>
              </Trans>
            </label>
          </div>
          <div className="flex justify-start gap-4">
            <Button
              onClick={() => {
                const tempBasicData = JSON.parse(JSON.stringify(basicData));
                resetCreateAccountSlice();
                setBasicData({
                  ...tempBasicData,
                });
                setActiveStep(0);
              }}>
              {t("UserAuth06.Step2.Back")}
            </Button>
            <Button
              isDisabled={!watch("acceptToS") || !isValid}
              type="submit"
              variation="primary"
              data-testid="next-button">
              {t("UserAuth06.Step2.Next")}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default Step2;
