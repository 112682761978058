import { jwtDecode } from "jwt-decode";

export const validateLocalStorageData = (): boolean => {
  try {
    const accessToken = localStorage.getItem("atmark-accessToken");
    const refreshToken = localStorage.getItem("atmark-refreshToken");
    const userData = localStorage.getItem("atmark-encryptedUserData");

    if (!accessToken || !refreshToken || !userData) {
      return false;
    }

    const decodedToken = jwtDecode(accessToken);

    if (!decodedToken) {
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
