import { DateTime } from "luxon";
import { Device } from "../pages/DeviceManagementPage/DeviceManagement.type";

import CryptoJS from "crypto-js";

// ja locale.
export function formatTime(timestamp: number): string | null {
  if (!timestamp) return null;
  const formattedDate = new Date(timestamp * 1000).toLocaleString("ja");
  return formattedDate;
}

// check the truthiness of the parameter, 0 is accepted as truthy.
export function checkTruthyValue(value: unknown): boolean {
  return value || value === 0 ? true : false;
}

// format the filesize for it to have a nicer look, this function use base 10 instead of base 2.
// eg: 100000 bytes => 10 MB.
export function formatFileSize(bytes: number, decimalPoint?: number): string {
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (bytes == 0) return "0 B";
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// format a timestamp to a Date String in ja locale.
export function formatTimestampToSeconds(timestamp: EpochTimeStamp): string {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedTimestamp = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return formattedTimestamp;
}

// export an array of data to a csv file.
export function exportToCSV(filename: string, data: object[]): void {
  const keys = Object.keys(data[0]);
  let res = Object.keys(data[0]).join(",") + "\n";

  data.forEach((obj) => {
    res += keys.map((key) => obj[key]).join(",") + "\n";
  });

  const blob = new Blob([res], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function encryptData(claim: object) {
  const secretKey = import.meta.env.VITE_COGNITO_USER_POOL_ID;
  return CryptoJS.AES.encrypt(JSON.stringify(claim), secretKey).toString();
}

export function decryptData(cipherText: string | null) {
  if (!cipherText) {
    return null;
  }
  const secretKey = import.meta.env.VITE_COGNITO_USER_POOL_ID;

  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function toCapitalizeCase(str: string, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function toPascalCase(str: string) {
  return toCapitalizeCase(str, true).replace(/\s/g, "");
}

export function timestampToDate(timestamp: EpochTimeStamp) {
  return DateTime.fromMillis(timestamp).toFormat("yyyy-MM-dd");
}

export function convertTimestampToLocalDate(timestamp) {
  // Create a new Date object with the provided timestamp
  const date = new Date(timestamp);
  // Get the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate the components in the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getTimezone() {
  // if offset equals -60 then the time zone offset is UTC+01
  const getTimezone = new Date().getTimezoneOffset() / -60;
  if (getTimezone === 0) return `UTC+00:00`;
  if (getTimezone > 0) return `UTC+${getTimezone.toString().padStart(2, "0")}:00`;
  if (getTimezone < 0) return `UTC-${(getTimezone * -1).toString().padStart(2, "0")}:00`;
}

export function convertDateTime(date: string) {
  return DateTime.fromMillis(new Date(date).valueOf()).toFormat("yyyy/MM/dd HH:mm:ss '(UTC'ZZ')'");
}

export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}
