import React from "react";
import ReactDOM from "react-dom/client";
import { Theme, ThemeProvider } from "@aws-amplify/ui-react";
import { ApolloProvider } from "@apollo/client";

import "./locales/i18n.ts";

// WARNING: DO NOT CHANGE THE ORDER OF CSS FILES!!!
import "./styles/index.scss";
import "./styles/custom-checkbox.scss";
import "@aws-amplify/ui-react/styles.css";
import "./styles/theme-override.scss";

import AppRoutes from "./routes/Routes.tsx";
import { client } from "./api/client.tsx";
import { Amplify } from "aws-amplify";

const theme: Theme = {
  name: "atmark-theme",
  tokens: {
    components: {
      divider: {
        borderColor: "#0000001A",
        opacity: "1",
      },
      button: {
        primary: {
          error: {
            backgroundColor: { value: "#bf4040" }, // rgb(191, 64, 64) hsl(0, 50%, 50%)
            _hover: {
              backgroundColor: { value: "#9b4040" },
            },
            _focus: {
              backgroundColor: { value: "#954040" },
            },
            _active: {
              backgroundColor: { value: "#954040" },
            },
          },
        },
      },
      card: {
        borderRadius: { value: "4px" },
        outlined: {
          borderRadius: { value: "4px" },
        },
      },
      badge: {
        success: {
          backgroundColor: { value: "#D6F5DB" },
        },
        warning: {
          backgroundColor: { value: "#FFFFB3" },
        },
        info: {
          backgroundColor: { value: "#B8CEF9" },
        },
        error: {
          backgroundColor: { value: "#F5BCBC" },
          color: { value: "#660000" },
        },
      },
      dropzone: {
        backgroundColor: "#fafafa",
        borderColor: "#808080",
        borderRadius: "0px",
        _disabled: {
          borderColor: "#D4D4D4",
        },
      },
    },
    fonts: {
      default: {
        static: "Poppins, Noto Sans JP, Helvetica, sans-serif",
        variable: "Poppins, Noto Sans JP, Helvetica, sans-serif",
      },
    },
  },
};

// Amplify configuration for subscriptions
Amplify.configure({
  API: {
    GraphQL: {
      endpoint: import.meta.env.VITE_GRAPHQL_URI,
      region: import.meta.env.VITE_APPSYNC_REGION,
      defaultAuthMode: import.meta.env.VITE_APPSYNC_AUTH_TYPE,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
