import { useMemo } from "react";
import { Alert, Button, Heading, TextField } from "@aws-amplify/ui-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IStageProps, STAGE } from "../LoginPage.type";
import yup from "@/locales/validation";
import { useMutation } from "@apollo/client";
import { REQUEST_RESET_PASSWORD } from "../queries";
import useBoundStore from "@/stores";

const ForgetPasswordStage1 = ({ setStage }: IStageProps) => {
  const schema = yup.object({
    email: yup.string().email("CommonError.FieldFormat").required("CommonError.FieldRequired"),
  });

  const { t } = useTranslation();
  const [setEncryptedKey, setEmail] = useBoundStore((state) => [state.setEncryptedKey, state.setEmail]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const [requestResetPassword, { loading, error }] = useMutation(REQUEST_RESET_PASSWORD);
  const requestResetPasswordErrors = useMemo(() => {
    return [error?.graphQLErrors[0]?.message, (error?.networkError as any)?.result?.errors?.[0]?.message].filter(
      Boolean,
    );
  }, [error]);

  const onFormSubmit = async (data) => {
    try {
      const { email } = data;
      const response = await requestResetPassword({
        variables: { email },
      });
      const encryptedKey = response.data.requestResetPassword;
      setEncryptedKey(encryptedKey);
      setEmail(email);
      setStage(STAGE.FORGET_PASSWORD_STAGE_2);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="flex flex-col gap-4 p-8">
      <Heading level={3}>{t("ResetPasswordHeader")}</Heading>
      <TextField
        {...register("email")}
        label={t("User.EmailAddress")}
        placeholder={String(t("EmailPlaceholder"))}
        hasError={Boolean(errors?.email)}
        errorMessage={errors?.email && String(t(String(errors.email.message), { field: t("User.EmailAddress") }))}
        data-testid="email-input"
      />
      {error && (
        <Alert
          variation="error"
          isDismissible
          data-testid="error-message">
          {error.message === "User not found."
            ? t("User.Error.NotFound")
            : requestResetPasswordErrors.includes("UserNotConfirmed")
              ? t("User.Error.UserNotConfirmed")
              : error.message}
        </Alert>
      )}
      <Button
        minHeight={"41.6px"}
        type="submit"
        isDisabled={!isValid || loading}
        isLoading={loading}
        variation="primary"
        data-testid="send-code-button">
        {t("User.SendCodeBtn")}
      </Button>
      <Button
        isDisabled={loading}
        size="small"
        variation="link"
        onClick={() => {
          setStage(STAGE.INITIAL);
        }}
        data-testid="return-to-signin-button">
        {t("ReturnToSignIn")}
      </Button>
    </form>
  );
};

export default ForgetPasswordStage1;
