import { gql } from "@apollo/client";

export const ON_PERMISSIONS_UPDATE_SUBSCRIPTION = gql`
  subscription MySubscription($user_id: ID!) {
    onPermissionsChanged(user_id: $user_id) {
      user_id
    }
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query User($userId: ID!) {
    user(user_id: $userId) {
      permissions
    }
  }
`;
