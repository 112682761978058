export const requestNewTokens = async (refreshToken: string): Promise<Response> => {
  const endpoint = import.meta.env.VITE_GRAPHQL_URI;
  const headers = {
    "content-type": "application/json",
    Authorization: import.meta.env.VITE_APPSYNC_API_ID,
  };

  const requestQuery = {
    operationName: "RefreshToken",
    query: `mutation RefreshToken($refreshToken: String!) {
      refreshToken(refresh_token: $refreshToken) {
        access_token
        refresh_token
      }
    }`,
    variables: {
      refreshToken,
    },
  };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestQuery),
  };

  try {
    return await fetch(endpoint, options);
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};
