import { Loader } from "@aws-amplify/ui-react";
import { ComponentProps, ReactNode, forwardRef, useMemo } from "react";

interface IMenuItem extends ComponentProps<"button"> {
  children: ReactNode | ReactNode[];
  isDisabled?: boolean;
  isLoading?: boolean;
}

const MenuItem = forwardRef<HTMLButtonElement, IMenuItem>((params, ref) => {
  const { children, isDisabled, isLoading, onClick, type, className, ...props } = params;
  const constructClassname = useMemo(() => {
    let className =
      "flex items-center justify-start px-4 py-2 min-h-[2.5rem] font-bold transition-all duration-[250ms] ease-in-out";
    if (!isDisabled && !isLoading) {
      className = className.concat(" hover:bg-primary hover:text-white");
    } else {
      className = className.concat(" cursor-not-allowed text-font-disabled");
    }
    return className;
  }, [isDisabled, isLoading]);

  return (
    <button
      ref={ref}
      disabled={isDisabled ?? isLoading}
      onClick={onClick}
      className={`${constructClassname} ${className}`}
      type={type ?? "button"}
      {...props}>
      {isLoading ? <Loader size="large" /> : children}
    </button>
  );
});

export default MenuItem;
