import { Alert, Button, Divider, Link, PasswordField, TextField } from "@aws-amplify/ui-react";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLazyQuery, useMutation } from "@apollo/client";

import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams, Link as RouterLink } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import useBoundStore from "@/stores";
import yup from "@/locales/validation";
import useAuth from "@/hooks/useAuth";
import { GET_USER_ID_BY_EMAIL, LOGIN } from "../queries";
import { IStageProps, STAGE } from "../LoginPage.type";
import { encryptData } from "@/utils/helper";
import { EmailRegex } from "@/constants/EmailRegex";
import { RESEND_OTP } from "@/pages/SignUpPage/queries";
import { ErrorCode } from "@/constants/ErrorCode";

const supportedLngs = ["en", "ja"];

const LoginStage = ({ setStage }: IStageProps) => {
  const [login, { loading, error }] = useMutation(LOGIN);
  const [sendOtp, { loading: sendOtpLoading }] = useMutation(RESEND_OTP);
  const [getUserIdByEmail, { loading: getUserIdLoading }] = useLazyQuery(GET_USER_ID_BY_EMAIL);

  const { t } = useTranslation();
  const { lang } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [setUsernameLocalStorage, setEmailLocalStorage, setLanguageLocalStorage, setVerificationData] = useBoundStore(
    (state) => [state.setUsername, state.setEmail, state.setLanguage, state.setVerificationData],
  );

  const redirectErrorCodes: string[] = [ErrorCode.EMAIL_IS_NOT_VERIFIED, ErrorCode.NEW_PASSWORD_REQUIRED];

  const schema = yup.object({
    email: yup
      .string()
      .required(`${t("Please fill out this field")}`)
      .matches(EmailRegex, `${t("CommonError.FieldFormat", { field: t("User.EmailAddress") })}`),
    password: yup.string().required(`${t("Please fill out this field")}`),
  });

  const { authenUser } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onLoginFormSubmit = async (data: { email: string; password: string }) => {
    try {
      const res = await login({
        variables: {
          payload: {
            email: data.email,
            password: data.password,
          },
        },
      });
      localStorage.setItem("atmark-accessToken", res.data.login.token.access_token);
      localStorage.setItem("atmark-refreshToken", res.data.login.token.refresh_token);
      localStorage.setItem(
        "atmark-encryptedUserData",
        encryptData({
          username: res.data.login.username,
          permissions: res.data.login.permissions,
          service_mgmt_role: res.data.login.service_mgmt_role,
        }),
      );
      setEmailLocalStorage(res.data.login.email);
      setUsernameLocalStorage(res.data.login.username);
      setLanguageLocalStorage(res.data.login.language);
      authenUser();
      navigate(state?.path || "/");
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      console.error(err);
      const errors = [err.graphQLErrors[0]?.message, err.networkError?.result?.errors?.[0]?.message].filter(Boolean);
      if (errors.includes(ErrorCode.EMAIL_IS_NOT_VERIFIED)) {
        const response = await getUserIdByEmail({
          variables: {
            email: data.email,
          },
        });

        const otpResponse = await sendOtp({
          variables: {
            input: {
              user_id: response.data.getUserIdByEmail,
            },
          },
        });
        setVerificationData({
          verification_token: otpResponse.data.sendVerification.verification_token,
          user_id: otpResponse.data.sendVerification.user_id,
        });
        navigate(`/${lang}/signup`, { state: { step: 3, disablePrevBtn: true, email: data.email } });
        return;
      }
      if (errors.includes(ErrorCode.NEW_PASSWORD_REQUIRED)) {
        setEmailLocalStorage(data.email);
        setStage(STAGE.CHANGE_PASSWORD);
      }
    }
  };

  useEffect(() => {
    if (lang) {
      if (supportedLngs.includes(lang)) {
        i18next.changeLanguage(lang);
      } else {
        navigate("/not-found");
      }
    }
  }, [lang, navigate]);

  return (
    <form
      onSubmit={handleSubmit(onLoginFormSubmit)}
      className="flex flex-col gap-4 px-8 py-4">
      <div>
        <TextField
          {...register("email", { required: true })}
          autoComplete="email"
          type="text"
          label={`${t("Login Email")}`}
          placeholder={`${t("EmailPlaceholder")}`}
          hasError={Boolean(errors?.email)}
          errorMessage={errors?.email?.message}
          data-testid="email-input"
        />
      </div>

      <div
        className="flex flex-col gap-2">
        <PasswordField
          {...register("password", { required: true })}
          label={`${t("Login Password")}`}
          placeholder={`${t("Login Enter Your Password")}`}
          hasError={Boolean(errors?.password)}
          errorMessage={errors?.password?.message}
          hideShowPassword={true}
          data-testid="password-input"
        />
        <Link
          fontSize={"14px"}
          onClick={() => setStage(STAGE.FORGET_PASSWORD_STAGE_1)}
          data-testid="reset-password-link">
          {t("ResetPassword")}
        </Link>
      </div>
      {error && !redirectErrorCodes.includes(error.message) && (
        <Alert
          variation="error"
          isDismissible
          hasIcon
          data-testid="error-message">
          <div className="whitespace-pre-line">{t(`${error.message}`)}</div>
        </Alert>
      )}

      <div className="flex justify-between">
        <div className="text-sm">
          <div>{t("SignupHeader")}</div>
          <RouterLink
            className="text-primary hover:text-primary-hover"
            to={`/${lang}/signup`}
            data-testid="account-signup">
            {t("SignupBtn")}
          </RouterLink>
        </div>
        <div>
          <Button
            minWidth={"100px"}
            isLoading={loading || sendOtpLoading || getUserIdLoading}
            loadingText={`${t("Login Signing in")}`}
            type="submit"
            variation="primary"
            data-testid="signin-button">
            {t("Login Sign in")}
          </Button>
        </div>
      </div>
      <Divider />
      <div className="mx-auto">
        <Link href={`/${lang}/inquiry`}>{t("Inquiry")}</Link>
      </div>
    </form>
  );
};

export default LoginStage;
