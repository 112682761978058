import React, { useEffect, useState } from "react";
import { Card, useTheme } from "@aws-amplify/ui-react";

import AtmarkLogo from "/images/AtmarkTechno_logo2.png";
import "./LoginPage.style.scss";
import { STAGE } from "./LoginPage.type";

import InitialStage from "./components/LoginStage";
import ForgetPasswordStage1 from "./components/ForgetPasswordStage1";
import ForgetPasswordStage2 from "./components/ForgetPasswordStage2";
import ChangePassword from "./components/ChangePassword";
import useBoundStore from "@/stores";

const LoginPage = () => {
  const { tokens } = useTheme();

  const [stage, setStage] = useState<STAGE>(STAGE.INITIAL);
  const setLanguageLocalStorage = useBoundStore((state) => state.setLanguage);

  useEffect(() => {
    setLanguageLocalStorage("DEFAULT");
  }, []);

  return (
    <div className="min-w-screen login-page flex min-h-screen flex-col items-center justify-start p-5">
      <div className="logo-wrapper">
        <img
          className="h-64 p-4"
          src={AtmarkLogo}
          alt={"atmark-logo"}
        />
      </div>
      <Card
        width={"480px"}
        maxWidth={"100%"}
        variation="outlined"
        padding={"0px"}
        boxShadow={tokens.shadows.medium as unknown as string}>
        {stage === STAGE.INITIAL && <InitialStage setStage={setStage} />}
        {stage === STAGE.FORGET_PASSWORD_STAGE_1 && <ForgetPasswordStage1 setStage={setStage} />}
        {stage === STAGE.FORGET_PASSWORD_STAGE_2 && <ForgetPasswordStage2 setStage={setStage} />}
        {stage === STAGE.CHANGE_PASSWORD && <ChangePassword setStage={setStage} />}
      </Card>
    </div>
  );
};

export default LoginPage;
