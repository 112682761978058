import { StateCreator } from "zustand";

import { sliceResetFns } from "..";

export type TenantBillingInfoSlice = {
  tenantBillingInfo: {
    is_corporation?: boolean;
    company_name: string;
    department: string;
    position: string;
    surname: string;
    surname_ruby: string;
    name: string;
    name_ruby: string;
    post_code: string;
    state: string;
    address_line1: string;
    address_line2: string;
    mail_address: string;
    phone_number: string;
  };
  setTenantBillingInfoData: (data) => void;
  reseTenantBillingInfo: () => void;
};

export const initialState = {
  tenantBillingInfo: {
    company_name: "",
    department: "",
    position: "",
    surname: "",
    surname_ruby: "",
    name: "",
    name_ruby: "",
    post_code: "",
    state: "",
    address_line1: "",
    address_line2: "",
    mail_address: "",
    phone_number: "",
  },
};

export const tenantBillingInfoSlice: StateCreator<TenantBillingInfoSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setTenantBillingInfoData: (data) => {
      set(() => ({ tenantBillingInfo: data }));
    },
    reseTenantBillingInfo: () => {
      set({ tenantBillingInfo: initialState.tenantBillingInfo });
    },
  };
};
