import { Divider } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { IFieldWrapperProps } from "./FieldWrapper.type";

export const FieldWrapper = ({ children, label, isRequired }: IFieldWrapperProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex gap-12">
        <div className="mb-auto mt-[9px] flex h-full w-[40%] items-center justify-between gap-2 font-medium">
          <div>{label}</div>
          {isRequired && <div className="text-error-msg">{t("UserAuth06.Step2.Required")}</div>}
        </div>
        <div className="flex-1 md:max-w-[35%]">{children}</div>
      </div>
      <Divider size="small" />
    </>
  );
};
