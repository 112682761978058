import { Alert, Button, Card, Divider } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { BasicDataEnum, IStepProps } from "../SignUp.type";
import useBoundStore from "@/stores";
import { DateTime } from "luxon";
import { useMutation } from "@apollo/client";
import { REGISTER_USER } from "../queries";

interface IItemWrapperProps {
  label: string;
  value: string;
}

const ItemWrapper = ({ label, value }: IItemWrapperProps) => {
  return (
    <>
      <div className="flex items-center gap-12">
        <div className="w-[40%] font-medium">
          <div>{label}</div>
        </div>
        <div className="flex-1 overflow-y-auto break-words">{value}</div>
      </div>
      <Divider size="small" />
    </>
  );
};

const Step3 = ({ setActiveStep }: IStepProps) => {
  const { t } = useTranslation();

  const [contractorData, basicData, setVerificationToken] = useBoundStore((state) => [
    state.contractorData,
    state.basicData,
    state.setVerificationData,
  ]);

  const [registerUser, { loading, error }] = useMutation(REGISTER_USER);

  const onConfirmBtnClick = async () => {
    const input = {
      tenant: {
        plan: basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL ? "TRIAL" : "CONTRACTED",
        trial_start_date:
          basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL ? DateTime.now().startOf("day").toMillis() : 0,
        trial_end_date:
          basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL
            ? DateTime.now().plus({ months: 3 }).endOf("month").toMillis()
            : 0,
        contract_application_date:
          basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL ? 0 : DateTime.now().startOf("day").toMillis(),
        // If usage plan is TRIAL, start date is 0.
        // If usage plan is CONTRACTED, check the start type.
        // If start type is IMMEDIATE, the start date is today at 00:00,
        // otherwise the start date is the first day of next month at 00:00
        contract_start_date:
          basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL
            ? 0
            : basicData.startType === BasicDataEnum.START_TYPE_IMMEDIATE
              ? DateTime.now().startOf("day").toMillis()
              : DateTime.now().plus({ months: 1 }).startOf("month").toMillis(),
        contract_end_date: 0,
      },
      user: {
        username: contractorData.username,
        email: contractorData.email,
        password: contractorData.password,
        confirm_password: contractorData.confirmPassword,
      },
      billing_info: {
        is_corporation: Boolean(basicData.contractorType === BasicDataEnum.CONTRACTOR_TYPE_CORPORATION),
        company_name:
          basicData.contractorType === BasicDataEnum.CONTRACTOR_TYPE_CORPORATION ? contractorData.company_name : "",
        department: contractorData.department ?? "",
        position: contractorData.position ?? "",
        surname: contractorData.surname,
        surname_ruby: contractorData.surname_ruby,
        name: contractorData.name,
        name_ruby: contractorData.name_ruby,
        post_code: contractorData.post_code_part1 + "-" + contractorData.post_code_part2,
        state: contractorData.state,
        address_line1: contractorData.address_line1,
        address_line2: contractorData.address_line2,
        mail_address: contractorData.email,
        phone_number:
          contractorData.phone_number_part1 +
          "-" +
          contractorData.phone_number_part2 +
          "-" +
          contractorData.phone_number_part3,
      },
    };
    try {
      const response = await registerUser({
        variables: {
          input,
        },
      });
      setVerificationToken(response.data.registerUser);
      setActiveStep(3);
    } catch (error) {
      console.error(error);
    }
  };

  const formData = [
    { label: "User.Username", value: contractorData.username },
    { label: "User.EmailAddress", value: contractorData.email },
    { label: "UserAuth06.Step2.Password", value: "******" },
    { label: "UserAuth06.Step2.CompanyName", value: contractorData.company_name },
    { label: "UserAuth06.Step2.Department", value: contractorData.department },
    { label: "UserAuth06.Step2.Position", value: contractorData.position },
    { label: "UserAuth06.Step2.FullName", value: contractorData.surname + " " + contractorData.name },
    { label: "UserAuth06.Step2.FullNameTrans", value: contractorData.surname_ruby + " " + contractorData.name_ruby },
    {
      label: "UserAuth06.Step2.PostalCode",
      value: contractorData.post_code_part1 + "-" + contractorData.post_code_part2,
    },
    { label: "UserAuth06.Step2.Prefecture", value: contractorData.state },
    { label: "UserAuth06.Step2.AddressLine1", value: contractorData.address_line1 },
    { label: "UserAuth06.Step2.AddressLine2", value: contractorData.address_line2 },
    {
      label: "UserAuth06.Step2.PhoneNumber",
      value:
        contractorData.phone_number_part1 +
        "-" +
        contractorData.phone_number_part2 +
        "-" +
        contractorData.phone_number_part3,
    },
  ];

  const corporationAttributes = [
    "UserAuth06.Step2.CompanyName",
    "UserAuth06.Step2.Department",
    "UserAuth06.Step2.Position",
  ];

  // Do not display attributes that belongs to users which have contractor type === "CORPORATION"
  if (basicData.contractorType !== BasicDataEnum.CONTRACTOR_TYPE_CORPORATION) {
    for (let i = 0; i < formData.length; i++) {
      if (corporationAttributes.includes(formData[i].label)) {
        formData.splice(i, 1);
        i--;
      }
    }
  }

  return (
    <Card variation="outlined">
      <div className="flex flex-col gap-4 p-2.5">
        {formData.map((item) => (
          <ItemWrapper
            key={item.label}
            label={t(item.label)}
            value={item.value ?? ""}
          />
        ))}
        {error && (
          <Alert
            variation="error"
            isDismissible>
            {error.message}
          </Alert>
        )}
        <div className="flex justify-start gap-4">
          <Button
            isDisabled={loading}
            onClick={() => setActiveStep(1)}>
            {t("UserAuth06.Step3.Back")}
          </Button>
          <Button
            isDisabled={loading}
            isLoading={loading}
            variation="primary"
            onClick={onConfirmBtnClick}
            data-testid="confirm-button">
            {t("UserAuth06.Step3.Confirm")}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Step3;
