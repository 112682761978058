const HamburgerIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1C14.2857 1 19.2857 1 20 1"
        stroke="black"
      />
      <path
        d="M0 9C14.2857 9 19.2857 9 20 9"
        stroke="black"
      />
      <path
        d="M0 17C14.2857 17 19.2857 17 20 17"
        stroke="black"
      />
    </svg>
  );
};

export default HamburgerIcon;
