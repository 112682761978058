import { Button, Card } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import AmplifyCheckmark from "@/components/ui/Icon/AmplifyCheckmark";
import { Link } from "react-router-dom";

const Step5 = () => {
  const { t, i18n } = useTranslation();

  return (
    <Card variation="outlined">
      <div className="flex flex-col items-center justify-center">
        <div className="text-green-80 p-3 rounded-full bg-green-10 mb-4">
          <AmplifyCheckmark
            width="40"
            height="40"
          />
        </div>
        <div className="font-semibold text-xl text-neutral-600">{t("UserAuth06.Step5.Success")}</div>
        <div className="font-medium text-neutral-500 mb-4">{t("UserAuth06.Step5.RegisterComplete")}</div>
        <Button variation="primary"
        data-testid="back-to-signin-button">
          <Link to={`/${i18n.language}/login`}>{t("UserAuth06.Step5.BackToSignIn")}</Link>
        </Button>
      </div>
    </Card>
  );
};

export default Step5;
