import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation RegisterUser($input: RegistrationInput) {
    registerUser(input: $input) {
      verification_token
      user_id
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VerifyUser($input: VerificationInput) {
    verifyUser(input: $input) {
      user_id
    }
  }
`;

export const RESEND_OTP = gql`
  mutation SendVerification($input: SendVerificationInput) {
    sendVerification(input: $input) {
      user_id
      verification_token
    }
  }
`;

export const ROLLBACK_REIGSTER = gql`
  mutation RollbackRegister($input: RollbackRegisterInput) {
    rollbackRegister(input: $input) {
      message
    }
  }
`;
