import { decryptData } from "../utils/helper";
import { STORAGE_KEYS, getLocalStorageValue } from "@/constants/LocalStorageKey";
import { JwtPayload, jwtDecode } from "jwt-decode";

export const useToken = () => {
  try {
    const token = getLocalStorageValue(STORAGE_KEYS.ACCESS_TOKEN);
    const { username, service_mgmt_role, permissions } = JSON.parse(
      decryptData(localStorage.getItem("atmark-encryptedUserData")) ?? "{}",
    );
    const claims: JwtPayload & { email: string; tenant_id: string } = jwtDecode(token);
    return {
      user_id: claims.sub,
      tenant_id: claims.tenant_id,
      email: claims.email,
      permissions: permissions,
      username,
      service_mgmt_role,
    };
  } catch (error) {
    return {};
  }
};
