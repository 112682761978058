import { List, X } from "@phosphor-icons/react";
import { useState } from "react";
import { sidebarUrlList } from "../Sidebar/Sidebar";
import ComponentGuard from "@/authorization/ComponentGuard";
import { FeatureCodeEnum } from "@/authorization/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, Variants, motion } from "framer-motion";

const SidebarMobile = () => {
  const { t, i18n } = useTranslation();

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const language = `/${i18n.language}`;

  const variants: { [key: string]: Variants } = {
    backdrop: {
      initial: { opacity: 0 },
      animate: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
        },
      },
      exit: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        },
      },
    },
    drawer: {
      initial: { x: -320, opacity: 1 },
      animate: { x: 0, opacity: 1 },
      exit: { x: -320, opacity: 1 },
    },
  };

  return (
    <>
      <motion.button
        onClick={() => setIsSidebarOpen(true)}
        className="sidebar-mobile sticky z-10 flex h-full max-w-[60px] grow items-start justify-center border-r bg-white p-3 sm:hidden"
        type="button">
        <List size={24} />
      </motion.button>
      <AnimatePresence mode="wait">
        {isSidebarOpen && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants.backdrop}
            transition={{ duration: 0.05 }}
            className="drawer-wrapper fixed inset-0 z-50 flex h-screen w-screen">
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="drawer-backdrop fixed inset-0 -z-10 bg-black/50"></button>
            <motion.div
              variants={variants.drawer}
              transition={{ duration: 0.15 }}
              className="drawer-content w-full max-w-xs bg-white text-lg">
              <div className="menu-btn flex max-h-[52px] items-center justify-between gap-4 px-5 py-3.5">
                <span className=" truncate font-semibold">{t("Menu")}</span>
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="toggle-sidebar-btn rounded-full">
                  <X
                    size={24}
                    weight="bold"
                  />
                </button>
              </div>
              <div className="sidebar-divider h-[1px] bg-divider"></div>
              <ol className="sidebar-list truncate">
                {sidebarUrlList.map((url) => (
                  <ComponentGuard
                    key={url.url}
                    mode="any"
                    featureCodeList={url.featureCodeList as FeatureCodeEnum[]}
                    userType={url.type}>
                    <li className="flex items-start justify-between">
                      <Link
                        to={language + url.url}
                        onClick={() => setIsSidebarOpen(false)}
                        className="grow px-5 py-3.5 font-medium hover:bg-neutral-100"
                        data-testid="menu-device-mgmt">
                        {t(url.label)}
                      </Link>
                    </li>
                  </ComponentGuard>
                ))}
              </ol>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SidebarMobile;
