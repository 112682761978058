export function calculateMatchingPercentage(string1, string2) {
  const maxLength = Math.max(string1.length, string2.length);
  const distance = levenshteinDistance(string1, string2);
  const matchingPercentage = ((maxLength - distance) / maxLength) * 100;

  return parseInt(matchingPercentage.toFixed(2));
}

export function levenshteinDistance(string1, string2) {
  const dp = Array(string1.length + 1)
    .fill(null)
    .map(() => Array(string2.length + 1).fill(null));

  for (let i = 0; i <= string1.length; i++) {
    dp[i][0] = i;
  }

  for (let j = 0; j <= string2.length; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= string1.length; i++) {
    for (let j = 1; j <= string2.length; j++) {
      const substitutionCost = string1[i - 1] !== string2[j - 1] ? 1 : 0;
      dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + substitutionCost);
    }
  }

  return dp[string1.length][string2.length];
}
