export enum TenantPlanEnum {
  CONTRACTED = "CONTRACTED",
  TRIAL = "TRIAL",
}

export enum TenantStateEnum {
  EXAMINATION = "EXAMINATION",
  CHANGING = "CHANGING",
  WAITING = "WAITING",
  REGISTERED = "REGISTERED",
  USING = "USING",
  CANCELING = "CANCELING",
  CANCELED = "CANCELED",
  SUSPEND = "SUSPEND",
  EXPIRED = "EXPIRED",
}

export interface Tenant {
  tenant_id: string;
  plan: TenantPlanEnum;
  tenant_state: TenantStateEnum;
}
