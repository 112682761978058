import { FeatureCodeEnum, PermissionEnum, SharedRouteEnum, StaffRouteEnum, UserRouteEnum, routeKey } from "./constants";

// Masterdata for authorization
// Key is the feauture code, value is an array of permissions that have full or partial access to the feature.
export const featureCodePermissionsMap: Map<FeatureCodeEnum, PermissionEnum[]> = new Map([
  // For normal users.
  [FeatureCodeEnum.USER_MGMT_08, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.USER_MGMT_09, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_02, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.INVOICE_INVOICE_02, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.INVOICE_INVOICE_03, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.INVOICE_INVOICE_04, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.INVOICE_INVOICE_06, [PermissionEnum.TENANT_MGMT]],

  [FeatureCodeEnum.USER_MGMT_01, [PermissionEnum.USER_MGMT]],
  [FeatureCodeEnum.USER_MGMT_02, [PermissionEnum.USER_MGMT]],
  [FeatureCodeEnum.USER_MGMT_03, [PermissionEnum.USER_MGMT]],
  [FeatureCodeEnum.USER_ROLE_01, [PermissionEnum.USER_MGMT]],
  [FeatureCodeEnum.USER_ROLE_02, [PermissionEnum.USER_MGMT]],

  [FeatureCodeEnum.DEVICE_MGMT_01, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_MGMT_05, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_MGMT_08, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_MGMT_18, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_DATA_04, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_BROWSE_01, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_BROWSE_02, [PermissionEnum.EVENT_VIEW]],
  [FeatureCodeEnum.DEVICE_BROWSE_03, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_BROWSE_04, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_STAT_01, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_STAT_02, [PermissionEnum.DEVICE_VIEW]],
  [FeatureCodeEnum.DEVICE_STAT_03, [PermissionEnum.DEVICE_VIEW]],

  [FeatureCodeEnum.DEVICE_MGMT_03, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_04, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_12, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_13, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_20, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_22, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_24, [PermissionEnum.DEVICE_MGMT]],
  [FeatureCodeEnum.DEVICE_MONITOR_01, [PermissionEnum.DEVICE_MGMT]],

  [FeatureCodeEnum.DEVICE_MGMT_06, [PermissionEnum.DEVICE_GRP_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_07, [PermissionEnum.DEVICE_GRP_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_09, [PermissionEnum.DEVICE_GRP_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_10, [PermissionEnum.DEVICE_GRP_MGMT]],
  [FeatureCodeEnum.DEVICE_MGMT_11, [PermissionEnum.DEVICE_GRP_MGMT]],

  [FeatureCodeEnum.NOTICE_NOTICE_01, [PermissionEnum.EVENT_VIEW]],
  [FeatureCodeEnum.NOTICE_NOTICE_04, [PermissionEnum.EVENT_VIEW]],
  [FeatureCodeEnum.NOTICE_NOTICE_05, [PermissionEnum.EVENT_VIEW]],

  [FeatureCodeEnum.DEVICE_REMOTE_06, [PermissionEnum.CMD_VIEW]],

  [FeatureCodeEnum.DEVICE_FW_05, [PermissionEnum.FWUPDATE_VIEW]],

  [FeatureCodeEnum.DEVICE_REMOTE_01, [PermissionEnum.CMD_MGMT, PermissionEnum.CMD_VIEW]],
  [FeatureCodeEnum.DEVICE_REMOTE_03, [PermissionEnum.CMD_MGMT, PermissionEnum.CMD_VIEW]],

  [FeatureCodeEnum.DEVICE_REMOTE_04, [PermissionEnum.CMD_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_05, [PermissionEnum.CMD_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_07, [PermissionEnum.CMD_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_08, [PermissionEnum.CMD_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_09, [PermissionEnum.CMD_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_10, [PermissionEnum.CMD_MGMT]],

  [FeatureCodeEnum.DEVICE_FW_03, [PermissionEnum.FWUPDATE_MGMT, PermissionEnum.FWUPDATE_VIEW]],
  [FeatureCodeEnum.DEVICE_FW_07, [PermissionEnum.FWUPDATE_MGMT, PermissionEnum.FWUPDATE_VIEW]],

  [FeatureCodeEnum.DEVICE_FW_08, [PermissionEnum.FWUPDATE_MGMT]],
  [FeatureCodeEnum.DEVICE_REMOTE_11, [PermissionEnum.CMD_MGMT]],

  [FeatureCodeEnum.DEVICE_FW_04, [PermissionEnum.FWUPDATE_MGMT]],
  [FeatureCodeEnum.DEVICE_FW_06, [PermissionEnum.FWUPDATE_MGMT]],

  [FeatureCodeEnum.DEVICE_FW_01, [PermissionEnum.FIRMWARE_VIEW]],

  [FeatureCodeEnum.DEVICE_FW_02, [PermissionEnum.FIRMWARE_MGMT]],

  // For staff
  [FeatureCodeEnum.COMMON_ADMIN_01, [PermissionEnum.USER_MGMT]],
  [FeatureCodeEnum.COMMON_ADMIN_02, [PermissionEnum.TENANT_MGMT]],
  [FeatureCodeEnum.NOTICE_NOTICE_02, [PermissionEnum.NOTICE_MGMT]],
]);

// Each route may contains one or more features, this constant is used to keep track of it.
// Key is the route regex, value is an array of feature that belongs to that route.
export const routeFeatureCodesMap = new Map<routeKey, FeatureCodeEnum[]>([
  [
    UserRouteEnum.USER_LIST,
    [
      FeatureCodeEnum.USER_MGMT_01,
      FeatureCodeEnum.USER_MGMT_02,
      FeatureCodeEnum.USER_MGMT_03,
      FeatureCodeEnum.USER_ROLE_02,
    ],
  ],
  [UserRouteEnum.USER_DETAIL, [FeatureCodeEnum.USER_ROLE_02]],
  [
    UserRouteEnum.DEVICE_MANAGEMENT,
    [
      FeatureCodeEnum.DEVICE_MGMT_01,
      FeatureCodeEnum.DEVICE_MGMT_09,
      FeatureCodeEnum.DEVICE_MGMT_12,
      FeatureCodeEnum.DEVICE_MGMT_18,
      FeatureCodeEnum.DEVICE_REMOTE_04,
    ],
  ],
  [
    UserRouteEnum.DEVICE_DETAIL,
    [
      FeatureCodeEnum.DEVICE_MGMT_09,
      FeatureCodeEnum.DEVICE_MGMT_13,
      FeatureCodeEnum.DEVICE_DATA_04,
      FeatureCodeEnum.DEVICE_BROWSE_01,
      FeatureCodeEnum.DEVICE_BROWSE_02,
      FeatureCodeEnum.DEVICE_REMOTE_01,
      FeatureCodeEnum.DEVICE_REMOTE_09,
      FeatureCodeEnum.DEVICE_FW_03,
      FeatureCodeEnum.DEVICE_MONITOR_01,
    ],
  ],
  [UserRouteEnum.DEVICE_CREATE, [FeatureCodeEnum.DEVICE_MGMT_03, FeatureCodeEnum.DEVICE_MGMT_04]],
  [UserRouteEnum.DEVICE_GROUP_MANAGEMENT, [FeatureCodeEnum.DEVICE_MGMT_05, FeatureCodeEnum.DEVICE_MGMT_07]],
  [UserRouteEnum.DEVICE_GROUP_CREATE, [FeatureCodeEnum.DEVICE_MGMT_06]],
  [
    UserRouteEnum.DEVICE_GROUP_DETAIL,
    [
      FeatureCodeEnum.DEVICE_MGMT_08,
      FeatureCodeEnum.DEVICE_MGMT_09,
      FeatureCodeEnum.DEVICE_MGMT_10,
      FeatureCodeEnum.DEVICE_MGMT_11,
      FeatureCodeEnum.DEVICE_BROWSE_03,
      FeatureCodeEnum.DEVICE_REMOTE_03,
      FeatureCodeEnum.DEVICE_FW_07,
    ],
  ],
  [UserRouteEnum.TENANT_SETTINGS, [FeatureCodeEnum.DEVICE_REMOTE_02]],

  [UserRouteEnum.USAGE_BILLING_MANAGEMENT, [FeatureCodeEnum.USER_MGMT_09, FeatureCodeEnum.INVOICE_INVOICE_02]],
  [UserRouteEnum.EXECUTE_ARBITRARY_COMMAND, [FeatureCodeEnum.DEVICE_REMOTE_11]],
  [UserRouteEnum.EXECUTE_FIRMWARE_UPDATE, [FeatureCodeEnum.DEVICE_FW_08]],

  // For Atmark staffs only.
  [StaffRouteEnum.CREATE_NOTICE, [FeatureCodeEnum.NOTICE_NOTICE_02]],
  [StaffRouteEnum.TENANT_MANAGEMENT, [FeatureCodeEnum.COMMON_ADMIN_02]],
  [StaffRouteEnum.TENANT_DETAIL, [FeatureCodeEnum.COMMON_ADMIN_02]],
  [StaffRouteEnum.USER_MANAGEMENT, [FeatureCodeEnum.COMMON_ADMIN_02]],
  [StaffRouteEnum.USER_MANAGEMENT_DETAIL, [FeatureCodeEnum.COMMON_ADMIN_02]],
  [StaffRouteEnum.ADMIN_USER_MANAGEMENT, [FeatureCodeEnum.COMMON_ADMIN_01]],

  // Shared routes that can be accessed by any authenticated user.
  [SharedRouteEnum.DASHBOARD, []],
  [SharedRouteEnum.USER_SETTINGS, []],
  [SharedRouteEnum.NOTIFICATION_LIST, []],
]);

// consruct a map between the routes and the permissions based on the routeFeatureCodes above.
export const constructRoutePermissionsMap = (): Map<routeKey, PermissionEnum[]> => {
  const routePermissionsMap = new Map<routeKey, PermissionEnum[]>();
  // construct a route - permissions map based on the predefined route - feature codes map.
  routeFeatureCodesMap.forEach(function (permissionArray, route) {
    const permissionSet = new Set<PermissionEnum>();
    permissionArray.forEach(function (permission) {
      if (featureCodePermissionsMap.has(permission)) {
        featureCodePermissionsMap.get(permission)?.forEach((role) => permissionSet.add(role));
      }
    });
    routePermissionsMap.set(route, Array.from(permissionSet.values()));
  });
  return routePermissionsMap;
};
