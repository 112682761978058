// this slice is used to store the default filter object
// for the notification list page.

import { StateCreator } from "zustand";
import { sliceResetFns } from "..";

const DEFAULT_FILTER_OBJECT = { category: { operator: "eq", value: "INFO" } };

type filterOjbectAttribute = {
  [key: string]: {
    operator: "eq" | "contains" | "ne";
    value: string;
  };
};

export type DefaultFilterSlice = {
  defaultFilterObject: filterOjbectAttribute;
  setDefaultFilterObject: (value: filterOjbectAttribute) => void;
  resetDefaultFilterObject: () => void;
};

const initialState = {
  defaultFilterObject: (function () {
    if (!localStorage.getItem("atmark-defaultFilterObject")) {
      localStorage.setItem("atmark-defaultFilterObject", JSON.stringify(DEFAULT_FILTER_OBJECT));
      return DEFAULT_FILTER_OBJECT;
    }
    return JSON.parse(localStorage.getItem("atmark-defaultFilterObject") as string);
  })(),
};

export const createDefaultFilterSlice: StateCreator<DefaultFilterSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setDefaultFilterObject: (value) => {
      localStorage.setItem("atmark-defaultFilterObject", JSON.stringify(value));
      set(() => ({ defaultFilterObject: value }));
    },
    resetDefaultFilterObject: () => {
      localStorage.setItem("atmark-defaultFilterObject", JSON.stringify(DEFAULT_FILTER_OBJECT));
      set(initialState);
    },
  };
};
