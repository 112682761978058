import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const breadCrumbMap = new Map<string, string>();

breadCrumbMap.set("device-management", "device-mgmt");

const Breadcrumb = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const breadcrumbs = location.pathname.split("/");
  breadcrumbs.splice(1, 1);

  let url = `/${t("lang")}`;

  // URL matched with this regex will not render a breadcrumb
  const blackListRegex = new RegExp(/\/\w{0,2}\/(device-group|users|admin-user-management)\/[\s\S]*/);

  if (new RegExp(blackListRegex).test(location.pathname)) {
    return null;
  }

  return (
    <ol className="flex items-center gap-2 bg-background p-3 !pb-0 text-lg font-medium !leading-[1.40625rem] sm:p-5">
      {breadcrumbs.map((el, index) => {
        const label = el.replace(/-/g, " ");

        if (index < breadcrumbs.length && el) {
          url += "/";
        }
        if (el) {
          url += el;
        }

        if (index === breadcrumbs.length - 1) {
          return (
            <span
              key={el + String(index)}
              className="inline-block truncate">
              {el ? t(`${el}`) : "Home"}
            </span>
          );
        }

        return (
          <div
            className="inline-flex items-center justify-center gap-2 truncate"
            key={el + String(index)}>
            <Link
              data-testid={`${label ? `breadcrumbs-${breadCrumbMap.get(el)}` : "breadcrumbs-home"}`}
              className="truncate text-link"
              to={url}>
              {label ? t(`${el}`) : "Home"}
            </Link>
            {location.pathname !== "/" && <span>{">"}</span>}
          </div>
        );
      })}
    </ol>
  );
};

export default Breadcrumb;
