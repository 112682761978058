import { createContext, useContext, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { resetAllStores } from "@/stores";
import { validateLocalStorageData } from "@/utils/validateLocalStorageData";

type AuthContextObject = {
  authed: boolean;
  authenUser: () => void;
  logout: () => Promise<void>;
};

// when clearing the local storage, skip these attributes
const excludedKeys = ["atmark-sidebarCollapsed", "atmark-defaultFilterObject", "atmark-lng", "atmark-lng_display"];

/**
 * DEPRECATED
 */
export const LOGOUT = gql`
  mutation Logout($refreshToken: String!) {
    logout(refresh_token: $refreshToken)
  }
`;
const AuthContext = createContext<AuthContextObject>({} as AuthContextObject);

export function useAuth() {
  const [authed, setAuthed] = useState(validateLocalStorageData());

  useEffect(() => {
    if (!authed) {
      const bc = new BroadcastChannel("general_channel");
      bc.postMessage("logout");

      Object.keys(localStorage).forEach((key) => {
        if (!excludedKeys.includes(key)) {
          localStorage.removeItem(key);
        }
      });

      resetAllStores();
    }
  }, [authed]);

  return {
    authed,
    authenUser() {
      setAuthed(true);
    },
    async logout() {
      try {
        setAuthed(false);
      } catch (err) {
        console.error(err);
        setAuthed(false);
      }
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(AuthContext);
}
