import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  data: string;
  className?: string;
}

const CustomErrorMessage = ({ data, className }: IProps) => {
  const { t } = useTranslation();
  const requirements = useMemo(
    () => [
      { regex: /^[\s\S]{8,99}$/, errorMessage: t("CommonError.Password.Length", { min: 8, max: 99 }) },
      { regex: /\d/, errorMessage: t("CommonError.Password.IncludeNumber") },
      { regex: /[a-z]/, errorMessage: t("CommonError.Password.IncludeLowercase") },
      { regex: /[A-Z]/, errorMessage: t("CommonError.Password.IncludeUppercase") },
      {
        regex: /[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\]/,
        errorMessage: t("CommonError.Password.IncludeSpecialCharacter"),
      },
    ],
    [t],
  );

  if (!data) {
    return (
      <div className={`flex flex-col text-sm text-error-msg ${className}`}>
        {t("CommonError.FieldRequired", { field: t("Password") })}
      </div>
    );
  }
  if (requirements.every((req) => new RegExp(req.regex).test(data))) {
    return <></>;
  }
  return (
    <div className={`flex flex-col text-sm text-error-msg ${className}`}>
      {requirements.map((req) => {
        const regex = new RegExp(req.regex);
        if (!regex.test(data)) {
          return <div key={req.errorMessage}>{req.errorMessage}</div>;
        }
      })}
    </div>
  );
};

export default CustomErrorMessage;
