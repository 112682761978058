import { StateCreator } from "zustand";
import { sliceResetFns } from "..";
import { Prefectures } from "@/constants/Prefectures";

export type CreateAccountSlice = {
  basicData: {
    usagePlan: string;
    startType: string | undefined;
    contractorType: string;
    email: string;
  };
  contractorData: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    surname: string;
    name: string;
    surname_ruby: string;
    name_ruby: string;
    post_code_part1: string;
    post_code_part2: string;
    state: string;
    address_line1: string;
    address_line2?: string;
    phone_number_part1: string;
    phone_number_part2: string;
    phone_number_part3: string;
    fax_number_part_1?: string;
    fax_number_part_2?: string;
    fax_number_part_3?: string;
    acceptToS: boolean;
    company_name: string;
    department?: string;
    position?: string;
  };
  verificationData: {
    verification_token: string;
    user_id: string;
  };
  setVerificationData: (data: { verification_token: string; user_id: string }) => void;
  setBasicData: (data: {
    usagePlan: string;
    startType: string | undefined;
    contractorType: string;
    email: string;
  }) => void;
  setContractorData: (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    surname: string;
    name: string;
    surname_ruby: string;
    name_ruby: string;
    post_code_part1: string;
    post_code_part2: string;
    state: string;
    address_line1: string;
    address_line2?: string;
    phone_number_part1: string;
    phone_number_part2: string;
    phone_number_part3: string;
    fax_number_part_1?: string;
    fax_number_part_2?: string;
    fax_number_part_3?: string;
    acceptToS: boolean;
    company_name: string;
    department?: string;
    position?: string;
  }) => void;
  resetCreateAccountSlice: () => void;
};

const initialState = {
  basicData: {
    usagePlan: "",
    contractorType: "",
    email: "",
    startType: "",
  },
  contractorData: {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    surname: "",
    name: "",
    surname_ruby: "",
    name_ruby: "",
    post_code_part1: "",
    post_code_part2: "",
    state: Prefectures["北海道"],
    address_line1: "",
    phone_number_part1: "",
    phone_number_part2: "",
    phone_number_part3: "",
    acceptToS: false,
    company_name: "",
  },
  verificationData: {
    verification_token: "",
    user_id: "",
  },
};

export const createAccountSlice: StateCreator<CreateAccountSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setBasicData: (data) => {
      set(() => ({ basicData: data }));
    },
    setContractorData: (data) => {
      set(() => ({ contractorData: data }));
    },
    setVerificationData: (data) => {
      set(() => ({ verificationData: data }));
    },
    resetCreateAccountSlice: () => {
      set(initialState);
    },
  };
};
