import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
  query User($userId: ID!) {
    user(user_id: $userId) {
      email
      username
    }
  }
`;
