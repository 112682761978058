import i18next from "i18next";
import enDict from "./en.json";
import enRoutes from "./en.routes.json";
import jaRoutes from "./ja.routes.json";
import jaDict from "./ja.json";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    routes: enRoutes,
    translation: enDict,
  },
  ja: {
    routes: jaRoutes,
    translation: jaDict,
  },
};

const language = localStorage.getItem("atmark-lng")?.toString();

if (!language) localStorage.setItem("atmark-lng", "ja");

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  supportedLngs: ["en", "ja"],
  lng: language,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["path"],
    lookupFromPathIndex: 0,
    checkWhitelist: true,
  },
});
