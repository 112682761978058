import { useState } from "react";

/**
 * @returns {Array<boolean | Function>} an array of 2 value:
 * - The first value is the value of the render state, this
 * can be used as the key to detect the state change and force
 * a deep re-render of the component.
 * - The second value is a function to toggle the render state
 * to re-render the component.
 */
export const useForceRender = () => {
  const [render, setRender] = useState<boolean>(false);
  const forceRender = () => setRender((prev) => !prev);
  return [render, forceRender] as const;
};
