import { Dispatch, SetStateAction } from "react";

export enum STAGE {
  INITIAL = "initial",
  CREATE_ACCOUNT_STAGE_2 = "create-aacount-stage-2",
  FORGET_PASSWORD_STAGE_1 = "forget-password-stage-1",
  FORGET_PASSWORD_STAGE_2 = "forget-password-stage-2",
  CHANGE_PASSWORD = "change-password",
  VERIFY_EMAIL = "verify-email",
}

export interface IStageProps {
  setStage: Dispatch<SetStateAction<STAGE>>;
}
