import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { resetAllStores } from "@/stores";
import { getLocalStorageValue, setLocalStorageValue, STORAGE_KEYS } from "@/constants/LocalStorageKey";
import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { requestNewTokens } from "@/utils/requestNewTokens";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { ErrorCode } from "@/constants/ErrorCode";

const excludedKeys = ["atmark-sidebarCollapsed", "atmark-defaultFilterObject", "atmark-lng", "atmark-lng_display"];

// whitelist operations are operations that don't require login to perform,
// so we don't need to check for token expiration when executing them
const whiteListOperation = [
  "Login",
  "Logout",
  "RegisterUser",
  "SendVerification",
  "VerifyUser",
  "RollbackRegister",
  "ForceChangePassword",
  "ResetPassword",
  "RequestResetPassword",
  "getUserIdByEmail",
];

// a set of action that needs to be performed when the user log out.
const logout = () => {
  // signal all other tabs to also logout.
  const bc = new BroadcastChannel("general_channel");
  bc.postMessage("logout");
  const currentLang = localStorage.getItem("atmark-lng") ?? "ja";
  localStorage.clear();
  localStorage.setItem("atmark-lng", currentLang);
  Object.keys(localStorage).forEach((key) => {
    if (!excludedKeys.includes(key)) {
      localStorage.removeItem(key);
    }
  });
  resetAllStores();
};

const authLink = setContext((_, { headers }) => {
  const accessToken = getLocalStorageValue(STORAGE_KEYS.ACCESS_TOKEN);

  return {
    headers: {
      ...headers,
      "x-access-token": accessToken ?? "",
      Authorization: import.meta.env.VITE_APPSYNC_API_ID,
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      console.error(message);
      if (message === ErrorCode.USER_IS_DISABLED) {
        logout();
      }
    });
  }
});

const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URI });

const link = ApolloLink.from([
  createSubscriptionHandshakeLink(
    {
      url: import.meta.env.VITE_GRAPHQL_URI,
      region: import.meta.env.VITE_APPSYNC_REGION,
      auth: {
        type: "AWS_LAMBDA",
        token: import.meta.env.VITE_APPSYNC_API_ID,
      },
    },
    httpLink,
  ),
]);

// eslint-disable-next-line prefer-const
export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    new TokenRefreshLink({
      accessTokenField: "refreshToken",
      isTokenValidOrUndefined: async (operation: Operation) => {
        if (whiteListOperation.includes(operation.operationName)) {
          return true;
        }

        const access_token = getLocalStorageValue(STORAGE_KEYS.ACCESS_TOKEN);

        if (!access_token) {
          return false;
        }

        const decoded = jwtDecode(access_token);

        if (
          !decoded ||
          (decoded.exp &&
            (DateTime.now().toSeconds() >= decoded.exp || decoded.exp * 1000 - DateTime.now().toMillis() <= 1500))
        ) {
          return false;
        }

        return true;
      },
      fetchAccessToken: async () => {
        const refresh_token = getLocalStorageValue(STORAGE_KEYS.REFRESH_TOKEN) ?? "";
        return await requestNewTokens(refresh_token);
      },
      handleFetch: (tokens) => {
        const formattedTokens = JSON.parse(JSON.stringify(tokens));
        setLocalStorageValue(STORAGE_KEYS.ACCESS_TOKEN, formattedTokens.access_token);
        setLocalStorageValue(STORAGE_KEYS.REFRESH_TOKEN, formattedTokens.refresh_token);
      },
      handleError: (error) => {
        console.log("Refresh token expired!");
        console.log(error);
        if (error.message === "[Token Refresh Link]: Unable to retrieve new access token") {
          logout();
        }
      },
    }),
    authLink,
    link,
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});
