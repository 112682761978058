import yup from "@/locales/validation"; // import yup for validation
import { validateInputLength } from "./SupportContact.helper"; // import helper validate input length

//constants for validation
export const MAX_BYTE_EMAIL = 128;
export const MAX_BYTE_PHONE_NUMBER = 32;
export const MAX_BYTE_MESSAGE = 4096;
export const MAX_BYTE_512 = 512;

//constants for error message
const COMMON_ERROR_LENGTH_BYTE_MSG_ID = "CommonError.LengthByte";

//Schema for validation
export const schema = yup.object({
  type: yup.string().trim(),
  name: yup
    .string()
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_512))
    .required("CommonError.FieldRequired")
    .default(""),
  companyName: yup
    .string()
    .trim()
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_512))
    .default(""),
  jobTitle: yup
    .string()
    .trim()
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_512))
    .default(""),
  email: yup
    .string()
    .trim()
    .email("CommonError.FieldFormat")
    .required("CommonError.FieldRequired")
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_EMAIL))
    .default(""),
  phoneNumber: yup
    .string()
    .trim()
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_PHONE_NUMBER))
    .default(""),
  inquiry: yup
    .string()
    .trim()
    .test("maxBytes", COMMON_ERROR_LENGTH_BYTE_MSG_ID, (value) => validateInputLength(value, MAX_BYTE_MESSAGE))
    .required("CommonError.FieldRequired")
    .default(""),
  acceptToPolicy: yup.boolean(),
});
