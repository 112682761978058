import { Fragment, ReactElement } from "react";
import { IStepperProps } from "./Stepper.type";
import "./Stepper.style.scss";
import AmplifyCheckmark from "../Icon/AmplifyCheckmark";

const Stepper = ({ children, activeStep }: IStepperProps) => {
  const titleList: string[] = [];

  children.forEach((child) => titleList.push(child.props.title));

  const getStepState = (step: number) => {
    if (step === activeStep) return "active";
    else if (step < activeStep) return "finished";
    else return "not-yet";
  };

  return (
    <div className="w-100 flex grow flex-col gap-4">
      <ol className="stepper-header z-10 flex w-full bg-background">
        {titleList.map((title, idx) => (
          <li
            key={title + String(idx)}
            className={`stepper-header-item relative flex flex-1 flex-col items-center ${getStepState(idx)}`}>
            <div className="stepper-header-item-icon flex h-10 w-10 items-center justify-center rounded-full">
              {getStepState(idx) === "finished" ? <AmplifyCheckmark /> : idx + 1}
            </div>
            <div className="stepper-header-item-text mx-2 mt-1 text-center font-medium">{title}</div>
          </li>
        ))}
      </ol>
      <div className="stepper-data mx-4 md:mx-6 lg:mx-8 xl:mx-14">
        {children.map((child: ReactElement, idx: number): ReactElement | undefined => {
          if (idx === activeStep) {
            return <Fragment key={child.props.title + String(idx)}>{child}</Fragment>;
          }
        })}
      </div>
    </div>
  );
};

export default Stepper;
