import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routeFeatureCodesMap } from "@/authorization/masterData";
import {
  FeatureCodeEnum,
  ServiceMgmtRole,
  SharedRouteEnum,
  StaffRouteEnum,
  UserRouteEnum,
} from "@/authorization/constants";
import ComponentGuard from "@/authorization/ComponentGuard";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { List, X } from "@phosphor-icons/react";
import { useState } from "react";

interface ISidebarUrlList {
  url: string;
  type?: ServiceMgmtRole;
  label: string;
  featureCodeList: FeatureCodeEnum[] | undefined;
}

export const sidebarUrlList: ISidebarUrlList[] = [
  {
    url: "",
    label: "Dashboard",
    featureCodeList: routeFeatureCodesMap.get(SharedRouteEnum.DASHBOARD),
  },
  {
    url: "/devices",
    type: ServiceMgmtRole.USER,
    label: "devices",
    featureCodeList: routeFeatureCodesMap.get(UserRouteEnum.DEVICE_MANAGEMENT),
  },
  {
    url: "/device-group",
    type: ServiceMgmtRole.USER,
    label: "device-group",
    featureCodeList: routeFeatureCodesMap.get(UserRouteEnum.DEVICE_GROUP_MANAGEMENT),
  },
  {
    url: "/accounts",
    type: ServiceMgmtRole.STAFF,
    label: "TenantManagement.Title",
    featureCodeList: routeFeatureCodesMap.get(StaffRouteEnum.TENANT_MANAGEMENT),
  },
  {
    url: "/users-mgmt",
    type: ServiceMgmtRole.STAFF,
    label: "UserMgmt.Title",
    featureCodeList: routeFeatureCodesMap.get(StaffRouteEnum.USER_MANAGEMENT),
  },
  {
    url: "/create-notification",
    type: ServiceMgmtRole.STAFF,
    label: "Notification.CreateNotification",
    featureCodeList: routeFeatureCodesMap.get(StaffRouteEnum.CREATE_NOTICE),
  },
  {
    url: "/execute-arbitrary-command",
    type: ServiceMgmtRole.USER,
    label: "ExecArbitraryCommand.Title",
    featureCodeList: routeFeatureCodesMap.get(UserRouteEnum.EXECUTE_ARBITRARY_COMMAND),
  },
  {
    url: "/execute-firmware-update",
    type: ServiceMgmtRole.USER,
    label: "ExecFirmwareUpdate.Title",
    featureCodeList: routeFeatureCodesMap.get(UserRouteEnum.EXECUTE_FIRMWARE_UPDATE),
  },
];

const SideBar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(() => {
    const state = String(localStorage.getItem("atmark-sidebarCollapsed"));
    if (state === "true") {
      return true;
    }
    return false;
  });
  const { t, i18n } = useTranslation();

  const language = `/${i18n.language}`;

  const variants: { [key: string]: Variants } = {
    sidebar: {
      open: { width: 300 },
      closed: { width: 48 },
    },
    overlayBtn: {
      show: { display: "block", transition: { delay: 0.25 } },
      hide: { display: "none" },
    },
    expandedContent: {
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { delay: 0.1 } },
      exit: { opacity: 0 },
    },
    menuBtn: {
      open: { padding: "10px 20px", transition: { delay: 0 } },
      closed: { padding: "10px 11.6px" },
    },
  };

  const setSidebarState = (state: boolean) => {
    setIsSidebarCollapsed(state);
    localStorage.setItem("atmark-sidebarCollapsed", JSON.stringify(state));
  };

  return (
    <motion.aside
      initial={false}
      animate={isSidebarCollapsed ? "closed" : "open"}
      variants={variants.sidebar}
      transition={{ duration: 0.25, type: "tween" }}
      className={"sidebar relative hidden h-full flex-col border-r bg-white text-lg sm:flex"}>
      <button
        onClick={() => {
          setSidebarState(!isSidebarCollapsed);
        }}
        className={`${isSidebarCollapsed ? "block" : "hidden"} overlay-btn absolute left-0 top-0 z-10 h-full w-full transition-all hover:bg-black/5`}></button>

      <motion.div
        variants={variants.menuBtn}
        animate={isSidebarCollapsed ? "closed" : "open"}
        className="menu-btn flex h-12 w-full items-center justify-between">
        <AnimatePresence
          mode="wait"
          initial={false}>
          {!isSidebarCollapsed && (
            <motion.span
              initial="initial"
              animate="animate"
              exit="exit"
              layout
              key="sidebar-title"
              variants={variants.expandedContent}
              className=" truncate font-semibold">
              {t("Menu")}
            </motion.span>
          )}
        </AnimatePresence>
        <button
          onClick={() => {
            setSidebarState(!isSidebarCollapsed);
          }}
          className="toggle-sidebar-btn">
          {isSidebarCollapsed ? (
            <List
              size={24}
              weight="bold"
            />
          ) : (
            <X
              size={24}
              weight="bold"
            />
          )}
        </button>
      </motion.div>
      <AnimatePresence
        mode="wait"
        initial={false}>
        {!isSidebarCollapsed && (
          <motion.div
            key="expanded-content"
            initial={"initial"}
            animate={"animate"}
            exit={"exit"}
            variants={variants.expandedContent}
            className="flex flex-col">
            <div className="sidebar-divider h-[1px] bg-divider"></div>
            <ol className="sidebar-list truncate">
              {sidebarUrlList.map((url) => (
                <ComponentGuard
                  key={url.url}
                  mode="any"
                  featureCodeList={url.featureCodeList as FeatureCodeEnum[]}
                  userType={url.type}>
                  <li className="flex items-start justify-between">
                    <Link
                      to={language + url.url}
                      className="grow px-5 py-3.5 font-medium hover:bg-neutral-100"
                      data-testid="menu-device-mgmt">
                      {t(url.label)}
                    </Link>
                  </li>
                </ComponentGuard>
              ))}
            </ol>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.aside>
  );
};

export default SideBar;
