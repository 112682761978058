import { PermissionEnum, routeKey } from "@/authorization/constants";
import { constructRoutePermissionsMap } from "@/authorization/masterData";
import { StateCreator } from "zustand";
import { sliceResetFns } from "..";

export type RoutePermissionsSlice = {
  routePermissionsMap: Map<routeKey, PermissionEnum[]>;
  setRoutePermissionsMap: (params: Map<routeKey, PermissionEnum[]>) => void;
  resetRoutePermissionsMap: () => void;
};

const initialState = {
  routePermissionsMap: constructRoutePermissionsMap(),
};

export const createRoutePermissionsSlice: StateCreator<RoutePermissionsSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setRoutePermissionsMap: (newMap) => {
      set(() => ({ routePermissionsMap: newMap }));
    },
    resetRoutePermissionsMap: () => set(initialState),
  };
};
