export enum PermissionEnum {
  TENANT_MGMT = "tenant_mgmt",
  USER_MGMT = "user_mgmt",
  CONN_TEST = "conn_test",
  DEVICE_VIEW = "device_view",
  DEVICE_MGMT = "device_mgmt",
  DEVICE_GRP_MGMT = "device_grp_mgmt",
  EVENT_VIEW = "event_view",
  JOB_VIEW = "job_view",
  CMD_VIEW = "cmd_view",
  FWUPDATE_VIEW = "fwupdate_view",
  CMD_MGMT = "cmd_mgmt",
  FWUPDATE_MGMT = "fwupdate_mgmt",
  FIRMWARE_VIEW = "firmware_view",
  FIRMWARE_MGMT = "firmware_mgmt",
  NOTICE_MGMT = "notice_mgmt",
  TENANT_USER = "tenant_user",
  TENANT_OWNER = "tenant_owner",
  STAFF_USER = "staff_user",
  STAFF_SALES = "staff_sales",
  STAFF_DEV = "staff_dev",
}

export enum FeatureCodeEnum {
  COMMON_CODE_01 = "common_code_01",
  COMMON_CODE_02 = "common_code_02",
  COMMON_INTERGRATION_01 = "common_intergration_01",
  COMMON_INTERGRATION_02 = "common_intergration_02",
  COMMON_INTERGRATION_03 = "common_intergration_03",
  COMMON_INTERGRATION_04 = "common_intergration_04",
  COMMON_ADMIN_01 = "common_admin_01",
  COMMON_ADMIN_02 = "common_admin_02",
  COMMON_ADMIN_03 = "common_admin_03",
  COMMON_ADMIN_04 = "common_admin_04",
  COMMON_ADMIN_05 = "common_admin_05",
  USER_AUTH_01 = "user_auth_01",
  USER_AUTH_02 = "user_auth_02",
  USER_AUTH_05 = "user_auth_05",
  USER_AUTH_06 = "user_auth_06",
  USER_AUTH_07 = "user_auth_07",
  USER_AUTH_08 = "user_auth_08",
  USER_AUTH_09 = "user_auth_09",
  USER_MGMT_01 = "user_mgmt_01",
  USER_MGMT_02 = "user_mgmt_02",
  USER_MGMT_03 = "user_mgmt_03",
  USER_MGMT_04 = "user_mgmt_04",
  USER_MGMT_05 = "user_mgmt_05",
  USER_MGMT_06 = "user_mgmt_06",
  USER_MGMT_07 = "user_mgmt_07",
  USER_MGMT_08 = "user_mgmt_08",
  USER_MGMT_09 = "user_mgmt_09",
  USER_MGMT_10 = "user_mgmt_10",
  USER_ROLE_01 = "user_role_01",
  USER_ROLE_02 = "user_role_02",
  DEVICE_MGMT_01 = "device_mgmt_01",
  DEVICE_MGMT_03 = "device_mgmt_03",
  DEVICE_MGMT_04 = "device_mgmt_04",
  DEVICE_MGMT_05 = "device_mgmt_05",
  DEVICE_MGMT_06 = "device_mgmt_06",
  DEVICE_MGMT_07 = "device_mgmt_07",
  DEVICE_MGMT_08 = "device_mgmt_08",
  DEVICE_MGMT_09 = "device_mgmt_09",
  DEVICE_MGMT_10 = "device_mgmt_10",
  DEVICE_MGMT_11 = "device_mgmt_11",
  DEVICE_MGMT_12 = "device_mgmt_12",
  DEVICE_MGMT_13 = "device_mgmt_13",
  DEVICE_MGMT_15 = "device_mgmt_15",
  DEVICE_MGMT_16 = "device_mgmt_16",
  DEVICE_MGMT_17 = "device_mgmt_17",
  DEVICE_MGMT_18 = "device_mgmt_18",
  DEVICE_MGMT_19 = "device_mgmt_19",
  DEVICE_MGMT_20 = "device_mgmt_20",
  DEVICE_MGMT_22 = "device_mgmt_22",
  DEVICE_MGMT_24 = "device_mgmt_24",
  DEVICE_DATA_01 = "device_data_01",
  DEVICE_DATA_02 = "device_data_02",
  DEVICE_DATA_03 = "device_data_03",
  DEVICE_DATA_04 = "device_data_04",
  DEVICE_BROWSE_01 = "device_browse_01",
  DEVICE_BROWSE_02 = "device_browse_02",
  DEVICE_BROWSE_03 = "device_browse_03",
  DEVICE_BROWSE_04 = "device_browse_04",
  DEVICE_REMOTE_01 = "device_remote_01",
  DEVICE_REMOTE_02 = "device_remote_02",
  DEVICE_REMOTE_03 = "device_remote_03",
  DEVICE_REMOTE_04 = "device_remote_04",
  DEVICE_REMOTE_05 = "device_remote_05",
  DEVICE_REMOTE_06 = "device_remote_06",
  DEVICE_REMOTE_07 = "device_remote_07",
  DEVICE_REMOTE_08 = "device_remote_08",
  DEVICE_REMOTE_09 = "device_remote_09",
  DEVICE_REMOTE_10 = "device_remote_10",
  DEVICE_REMOTE_11 = "device_remote_11",
  DEVICE_FW_01 = "device_fw_01",
  DEVICE_FW_02 = "device_fw_02",
  DEVICE_FW_03 = "device_fw_03",
  DEVICE_FW_04 = "device_fw_04",
  DEVICE_FW_05 = "device_fw_05",
  DEVICE_FW_06 = "device_fw_06",
  DEVICE_FW_07 = "device_fw_07",
  DEVICE_FW_08 = "device_fw_08",
  DEVICE_STAT_01 = "device_stat_01",
  DEVICE_STAT_02 = "device_stat_02",
  DEVICE_STAT_03 = "device_stat_03",
  DEVICE_MONITOR_01 = "device_monitor_01",
  DEVICE_MONITOR_02 = "device_monitor_02",
  NOTICE_NOTICE_01 = "notice_notice_01",
  NOTICE_NOTICE_02 = "notice_notice_02",
  NOTICE_NOTICE_03 = "notice_notice_03",
  NOTICE_NOTICE_04 = "notice_notice_04",
  NOTICE_NOTICE_05 = "notice_notice_05",
  NOTICE_CONFIG_01 = "notice_config_01",
  INVOICE_INVOICE_02 = "invoice_invoice_02",
  INVOICE_INVOICE_03 = "invoice_invoice_03",
  INVOICE_INVOICE_04 = "invoice_invoice_04",
  INVOICE_INVOICE_05 = "invoice_invoice_05",
  INVOICE_INVOICE_06 = "invoice_invoice_06",
  LOG_OP_01 = "log_op_01",
  LOG_OP_02 = "log_op_02",
  LOG_INFRA_01 = "log_infra_01",
  SUPPORT_CONTACT_01 = "support_contact_01",
  SUPPORT_HELP_01 = "support_help_01",
  SUPPORT_CHAT_01 = "support_chat_01",
  UI_M17N_01 = "ui_m17n_01",
}

// Route that belongs exclusively to tenant owners and users.
export enum UserRouteEnum {
  USER_LIST = "^/(en|ja)/users$",
  USER_DETAIL = "^/(en|ja)/users/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
  DEVICE_MANAGEMENT = "^/(en|ja)/devices$",
  DEVICE_DETAIL = "^/(en|ja)/devices/[0-9A-Za-z]+$",
  DEVICE_CREATE = "^/(en|ja)/devices/create$",
  DEVICE_GROUP_MANAGEMENT = "^/(en|ja)/device-group$",
  EXECUTE_ARBITRARY_COMMAND = "^/(en|ja)/execute-arbitrary-command$",
  EXECUTE_FIRMWARE_UPDATE = "^/(en|ja)/execute-firmware-update$",
  DEVICE_GROUP_CREATE = "^/(en|ja)/device-group/create$",
  DEVICE_GROUP_DETAIL = "^/(en|ja)/device-group/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
  USAGE_BILLING_MANAGEMENT = "^/(en|ja)/usage-billing$",
  TENANT_SETTINGS = "^/(en|ja)/account-settings$",
}

// Route that belongs exclusively to Atmark staffs.
export enum StaffRouteEnum {
  CREATE_NOTICE = "^/(en|ja)/create-notification$",
  ADMIN_USER_MANAGEMENT = "^/(en|ja)/admin-user-management$",
  TENANT_MANAGEMENT = "^/(en|ja)/accounts$",
  TENANT_DETAIL = "^/(en|ja)/accounts/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
  USER_MANAGEMENT = "^/(en|ja)/users-mgmt$",
  USER_MANAGEMENT_DETAIL = "^/(en|ja)/users-mgmt/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
}

// Route that are shared between staffs and users.
export enum SharedRouteEnum {
  DASHBOARD = "^/(en|ja)$",
  USER_SETTINGS = "^/(en|ja)/user-settings$",
  NOTIFICATION_LIST = "^/(en|ja)/notifications$",
}

export enum ServiceMgmtRole {
  USER = "user",
  STAFF = "staff",
  STAFF_DEV = "staff_dev",
  STAFF_SALES = "staff_sales",
}

export enum DBServiceMgmtRole {
  SALES = "SALES",
  DEV = "DEV",
}

export type routeKey = UserRouteEnum | StaffRouteEnum | SharedRouteEnum;
