import Stepper from "@/components/ui/Stepper/Stepper";
import StepperItem from "@/components/ui/Stepper/StepperItem";
import { useEffect, useState } from "react";
import Step1 from "./components/Step1/Step1";
import { useTranslation } from "react-i18next";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import useBoundStore from "@/stores";
import { useLocation } from "react-router-dom";

const SignUp = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  // 0 = "basic_information",
  // 1 = "create_account",
  // 2 = "confirm_information",
  // 3 = "verify_email",
  // 4 = "complete",
  const [activeStep, setActiveStep] = useState<number>(() => {
    // when navigate from login page, navigate the user to
    // the verify_email step since the first 3 steps are obsolete.
    if (state?.step) {
      return state.step;
    }
    return 0;
  });

  const resetCreateAccountSlice = useBoundStore().resetCreateAccountSlice;

  useEffect(() => {
    return () => {
      resetCreateAccountSlice();
      history.replaceState(null, "");
    };
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-start overflow-auto">
      <div className="flex w-full max-w-7xl items-center justify-center p-4">
        <Stepper activeStep={activeStep}>
          <StepperItem title={t("UserAuth06.StepperHeader.Step1")}>
            <Step1 setActiveStep={(step) => setActiveStep(step)} />
          </StepperItem>
          <StepperItem title={t("UserAuth06.StepperHeader.Step2")}>
            <Step2 setActiveStep={(step) => setActiveStep(step)} />
          </StepperItem>
          <StepperItem title={t("UserAuth06.StepperHeader.Step3")}>
            <Step3 setActiveStep={(step) => setActiveStep(step)} />
          </StepperItem>
          <StepperItem title={t("UserAuth06.StepperHeader.Step4")}>
            <Step4 setActiveStep={(step) => setActiveStep(step)} />
          </StepperItem>
          <StepperItem title={t("UserAuth06.StepperHeader.Step5")}>
            <Step5 />
          </StepperItem>
        </Stepper>
      </div>
    </div>
  );
};

export default SignUp;
