import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

interface IBaseModalProps {
  children: React.ReactElement | string;
  closeModal: () => void;
}

const BaseModal = ({ children, closeModal }: IBaseModalProps) => {
  const [open, setOpen] = useState<boolean>(true);

  const onCloseModal = () => {
    setOpen(false);
    setTimeout(() => closeModal(), 250);
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
          data-amplify-theme="atmark-theme"
          className="modal fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center">
          <button
            className="absolute -z-10 h-full w-full cursor-default bg-black/50"
            onClick={onCloseModal}></button>
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default BaseModal;
