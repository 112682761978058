import AtmarkLogo from "/images/AtmarkTechno_logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HeaderUser from "./NavbarTabs/HeaderUser";
import { Image } from "@aws-amplify/ui-react";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  return (
    <header className="flex w-full justify-between bg-[#222222] px-2.5 md:px-5">
      <Link
        to={`/${i18n.language}`}
        className="h-full">
        <img
          className="h-full max-h-[50px] min-h-[50px] object-contain"
          src={AtmarkLogo}
          alt="Company Logo"
          data-testid="header-logo"
        />
      </Link>
      <nav className="flex gap-5 px-2.5 md:gap-4 md:px-5">
        <Link
          to={"support-contact"}
          className="flex items-center justify-center gap-2 truncate font-bold text-white"
          data-testid="header-contact">
          <Image
            src={"/images/icons/ic_outline-mail.png"}
            alt=""
            width="24px"
            height="24px"
          />
          <span className="hidden truncate sm:block">{t("Contact")}</span>
        </Link>
        <HeaderUser />
      </nav>
    </header>
  );
};

export default Navbar;
