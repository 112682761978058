import { useToken } from "@/hooks/useToken";
import { DBServiceMgmtRole, FeatureCodeEnum, PermissionEnum, ServiceMgmtRole } from "./constants";
import { ReactNode } from "react";
import { featureCodePermissionsMap } from "./masterData";

interface IComponentGuardProps {
  featureCodeList: FeatureCodeEnum[];
  mode: "all" | "any";
  userType?: ServiceMgmtRole;
  children: ReactNode;
}

interface IPermissionsBasedComponentGuardProps {
  permissionList: PermissionEnum[];
  children: ReactNode;
  mode: "all" | "any";
}

/**
 * Validate user before rendering component
 * @param {FeatureCodeEnum[]} featureCodeList List of feature code to construct a permission list used to validate.
 * @param {string} mode Specify the logic for the validation:
 * - "all" means the user needs to have all of the required permissions constructed from feature code list in order for the component to be rendered.
 * - "any" means the user only needs to have at least one of the required permissions constructed from feature code list in order for the component to be rendered.
 * @param {ServiceMgmtRole} type If presented, addtionally check the "service_mgmt_role" attribute of the user:
 * - "staff" means the user needs to have their "service_mgmt_role" attribute set to "DEV" or "SALES".
 * - "staff_dev" means the user needs to have their "service_mgmt_role" attribute set to "DEV".
 * - "staff_sales" means the user needs to have their "service_mgmt_role" attribute set to "SALES".
 * - "user" means the user MUST have their "service_mgmt_role" attribute set to NULL.
 */
const ComponentGuard = ({ featureCodeList, mode, userType, children }: IComponentGuardProps): ReactNode => {
  const { permissions, service_mgmt_role } = useToken();
  const uniquePermissionList = new Set<PermissionEnum>();

  if (
    (userType === ServiceMgmtRole.STAFF && !service_mgmt_role) ||
    (userType === ServiceMgmtRole.STAFF_DEV && service_mgmt_role !== DBServiceMgmtRole.DEV) ||
    (userType === ServiceMgmtRole.STAFF_SALES && service_mgmt_role !== DBServiceMgmtRole.SALES) ||
    (userType === ServiceMgmtRole.USER && service_mgmt_role)
  ) {
    return;
  }

  // construct the list of roles required based on the feature codes.
  featureCodeList.forEach((permission) => {
    const permissionList = featureCodePermissionsMap.get(permission);
    permissionList?.forEach((role) => uniquePermissionList.add(role));
  });

  if (uniquePermissionList.size > 0) {
    if (mode === "all") {
      for (const permission of uniquePermissionList) {
        if (!permissions.includes(permission)) {
          return;
        }
      }
    } else if (mode === "any") {
      for (const role of uniquePermissionList) {
        if (permissions.includes(role)) {
          return children;
        }
      }
      return;
    }
  }

  return children;
};

/**
 * This guard is a simplified version of the component guard above.
 * @param {PermissionEnum[]} roles list of roles to check.
 * @param {string} mode Specify the logic for the validation:
 * - "all" means the user needs to have all of the required roles in order for the component to be rendered.
 * - "any" means the user only needs to have at least one of the required roles in order for the component to be rendered.
 */
export const PermissionsBasedComponentGuard = ({
  permissionList,
  mode,
  children,
}: IPermissionsBasedComponentGuardProps) => {
  const { permissions } = useToken();
  if (mode === "all") {
    for (const role of permissionList) {
      if (!permissions.includes(role)) {
        return;
      }
    }
  } else if (mode === "any") {
    for (const role of permissionList) {
      if (permissions.includes(role)) {
        return <>{children}</>;
      }
    }
    return;
  }
  return <>{children}</>;
};

export default ComponentGuard;
