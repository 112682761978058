import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($payload: LoginInput!) {
    login(payload: $payload) {
      token {
        access_token
        refresh_token
      }
      user_id
      tenant_id
      email
      email_verified
      username
      permissions
      language
      service_mgmt_role
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const GET_USER_ID_BY_EMAIL = gql`
  query getUserIdByEmail($email: String!) {
    getUserIdByEmail(email: $email)
  }
`;
