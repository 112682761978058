import { StateCreator } from "zustand";
import { sliceResetFns } from "..";
import { TenantPlanEnum } from "@/pages/TenantManagementPage/TenantManagement.type";

// Device Id list used when registering device
// Feature: Device-Mgmt-03

export type TenantPlanSlice = {
  tenantPlan: string;
  setTenantPlan: (setTenantPlan: string) => void;
  // deviceIdList: Set<string>;
};

const initialState = {
  tenantPlan: TenantPlanEnum.CONTRACTED,
  // deviceIdList: new Set<string>(),
};

export const createTenantPlanSlice: StateCreator<TenantPlanSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setTenantPlan: (tenantPlan: string) => {
      set(() => ({ tenantPlan: tenantPlan }));
    },
  };
};
