import ComponentGuard from "@/authorization/ComponentGuard";
import { FeatureCodeEnum, ServiceMgmtRole, StaffRouteEnum, UserRouteEnum } from "@/authorization/constants";
import useBoundStore from "@/stores";
import { Loader, Image } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { Props } from "./Header.interface";
import Menu from "../../Menu/Menu";
import MenuItem from "../../Menu/MenuItem";
import { routeFeatureCodesMap } from "@/authorization/masterData";
import { URL_SUPPORT_HELP } from "@/constants/SupportUrl";
import { URL_UPDATE_HISTORY } from "@/constants/constant";
import { useQuery } from "@apollo/client";
import { GET_USER_DATA } from "../queries";
import { useToken } from "@/hooks/useToken";

const HeaderUser: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { user_id } = useToken();

  const [open, setOpen] = useState<boolean>(false);

  const [username, email, setEmail, setUsername] = useBoundStore((state) => [
    state.username,
    state.email,
    state.setEmail,
    state.setUsername,
  ]);

  const { loading } = useQuery(GET_USER_DATA, {
    variables: {
      userId: user_id,
    },
    onCompleted(data) {
      if (data.user.email !== email) {
        setEmail(data.user.email);
      }
      if (data.user.username !== username) {
        setUsername(data.user.username);
      }
    },
  });

  const onSignoutBtnClick = async (): Promise<void> => {
    setOpen(!open);
    await logout();
  };

  return (
    <>
      {/* {loading &&
        ReactDOM.createPortal(
          <div className="modal fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center">
            <div className="backdrop absolute left-0 top-0 -z-10 h-full w-full bg-black/50"></div>
            <div className="flex flex-col items-center justify-center gap-4 text-lg font-medium">
              <Loader
                width={"64px"}
                height={"64px"}
              />
              <div className="flex items-baseline gap-1 text-white">
                {t("Signingout")} <LoadingAnimtion />
              </div>
            </div>
          </div>,
          document.body,
        )} */}
      <Menu
        open={open}
        setOpen={setOpen}
        menuAlign="end"
        trigger={
          <button className="flex items-center justify-center gap-2">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Image
                  src={"/images/icons/mdi_user-circle-outline.png"}
                  alt=""
                  width="24px"
                  height="24px"
                />
                <span
                  className="hidden truncate whitespace-pre-wrap font-bold text-white sm:block"
                  data-testid="header-user">
                  {username}
                </span>
              </>
            )}
          </button>
        }>
        <MenuItem
          data-testid="header-userinfo"
          onClick={() => {
            setOpen(!open);
            navigate("user-settings");
          }}>
          {t("UserSettings")}
        </MenuItem>
        <ComponentGuard
          featureCodeList={routeFeatureCodesMap.get(UserRouteEnum.USER_LIST) as FeatureCodeEnum[]}
          mode="any"
          userType={ServiceMgmtRole.USER}>
          <MenuItem
            data-testid="header-users"
            onClick={() => {
              setOpen(!open);
              navigate("users");
            }}>
            {t("UserManagement")}
          </MenuItem>
        </ComponentGuard>
        <ComponentGuard
          featureCodeList={routeFeatureCodesMap.get(StaffRouteEnum.ADMIN_USER_MANAGEMENT) as FeatureCodeEnum[]}
          mode="any"
          userType={ServiceMgmtRole.STAFF_DEV}>
          <MenuItem
            data-testid="header-users"
            onClick={() => {
              setOpen(!open);
              navigate("admin-user-management");
            }}>
            {t("AdminUserManagement")}
          </MenuItem>
        </ComponentGuard>
        <ComponentGuard
          featureCodeList={routeFeatureCodesMap.get(UserRouteEnum.USAGE_BILLING_MANAGEMENT) as FeatureCodeEnum[]}
          mode="any"
          userType={ServiceMgmtRole.USER}>
          <MenuItem
            data-testid="header-invoice"
            onClick={() => {
              setOpen(!open);
              navigate("usage-billing");
            }}>
            {t("User.UsageBillingMgmt.UsageBilling")}
          </MenuItem>
        </ComponentGuard>
        <ComponentGuard
          featureCodeList={routeFeatureCodesMap.get(UserRouteEnum.TENANT_SETTINGS) as FeatureCodeEnum[]}
          mode="any"
          userType={ServiceMgmtRole.USER}>
          <MenuItem
            data-testid="header-tenant"
            onClick={() => {
              setOpen(!open);
              navigate("account-settings");
            }}>
            {t("Tenant.Management")}
          </MenuItem>
        </ComponentGuard>
        <MenuItem
          data-testid="header-support-help"
          onClick={() => {
            setOpen(!open);
            window.open(URL_UPDATE_HISTORY);
          }}>
          {t("UpdateHistory")}
        </MenuItem>
        <MenuItem
          data-testid="header-support-help"
          onClick={() => {
            setOpen(!open);
            window.open(URL_SUPPORT_HELP);
          }}>
          {t("Help")}
        </MenuItem>
        <MenuItem
          data-testid="header-signout"
          onClick={onSignoutBtnClick}>
          {t("Signout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderUser;
