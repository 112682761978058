import SideBar from "../Sidebar/Sidebar.tsx";
import { Outlet } from "react-router-dom";
import Breadcrumb from "../Breadcrumb/Breadcrumb.tsx";
import Navbar from "../Navbar/Navbar.tsx";
import { ArrowUp } from "@phosphor-icons/react";
import SidebarMobile from "../SidebarMobile/SidebarMobile.tsx";
import { useMediaQuery } from "@/hooks/useMediaQuery.ts";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { GET_USER_PERMISSIONS, ON_PERMISSIONS_UPDATE_SUBSCRIPTION } from "./queries.ts";
import { useToken } from "@/hooks/useToken.ts";
import { getLocalStorageValue, setLocalStorageValue, STORAGE_KEYS } from "@/constants/LocalStorageKey.ts";
import { decryptData, encryptData } from "@/utils/helper.ts";
import { useForceRender } from "@/hooks/useForceRender.ts";
import PermissionsUpdateModal from "./components/PermissionsUpdateModal.tsx";

const ScrollToTop = ({ show }) => {
  const container = document.getElementById("content-container");

  const scrollTop = () => {
    if (container) {
      container.scrollTo({ top: 0 });
    }
    window.scrollTo({ top: 0 });
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.35 }}
          exit={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          id="scroll-top-btn"
          type="button"
          onClick={scrollTop}
          className="fixed bottom-4 right-5 z-10 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-neutral-500">
          <ArrowUp
            fill="white"
            size={20}
          />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

const MainLayout = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [customKey, forceRender] = useForceRender();
  const { user_id } = useToken();

  const isSmallScreen = useMediaQuery("(max-width: 640px)");

  const [showScrollTopBtn, setShowScrollTopBtn] = useState<boolean>(false);

  const { data, error } = useSubscription(ON_PERMISSIONS_UPDATE_SUBSCRIPTION, {
    variables: {
      user_id,
    },
  });

  const [getUserPermissions] = useLazyQuery(GET_USER_PERMISSIONS, {
    variables: {
      userId: user_id,
    },
  });

  useEffect(() => {
    if (data) {
      getUserPermissions({
        onCompleted(data) {
          const userData = JSON.parse(decryptData(getLocalStorageValue(STORAGE_KEYS.USER_DATA)) ?? "{}");
          if (userData.permissions) {
            userData.permissions = data.user.permissions;
            setLocalStorageValue(STORAGE_KEYS.USER_DATA, encryptData(userData));
            setShowModal(true);
          }
        },
      });
    }

    if (error) {
      console.error("Subscription error: ", error);
    }
  }, [data, error, getUserPermissions]);

  return (
    <div
      className="relative h-full"
      key={String(customKey)}>
      {showModal && (
        <PermissionsUpdateModal
          closeModal={() => {
            setShowModal(false);
          }}
          forceRender={forceRender}
        />
      )}
      <Navbar />
      <main className="flex h-[calc(100%-50px)]  overflow-hidden">
        {isSmallScreen ? <SidebarMobile /> : <SideBar />}
        <motion.div
          onScroll={() => {
            const container = document.getElementById("content-container") as HTMLDivElement;
            if (container.scrollTop > 200 && !showScrollTopBtn) {
              setShowScrollTopBtn(true);
            } else if (container.scrollTop <= 200 && showScrollTopBtn) {
              setShowScrollTopBtn(false);
            }
          }}
          id="content-container"
          className="content-container grow overflow-auto">
          <Breadcrumb />
          <div className="p-3 sm:p-5">
            <Outlet />
          </div>
          <ScrollToTop show={showScrollTopBtn} />
        </motion.div>
      </main>
    </div>
  );
};

export default MainLayout;
