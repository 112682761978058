/**
 * validate input length
 * @param text input value to validate
 * @param max input max length
 * @returns boolean
 */
export function validateInputLength(text, max) {
  const encoder = new TextEncoder();
  const byteLength = encoder.encode(text).length;
  return byteLength <= max;
}
