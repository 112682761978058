import { Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <main className="flex h-screen w-screen items-center justify-center">
      <div className="px-4 py-5 text-center">
        <h1 className="text-[192px] font-bold leading-none text-primary">404</h1>
        <h2 className="text-2xl font-semibold tracking-wide">Page not found</h2>
        <div className="mb-4 mt-4 max-w-md text-center font-normal">{t("PAGE_NOT_EXIST_404")}</div>
        <Link to={"/"}>
          <Button variation="primary">{t("BACK_TO_HOME")}</Button>
        </Link>
      </div>
    </main>
  );
};

export default NotFound;
