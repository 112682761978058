import { StateCreator } from "zustand";

import { TenantPlanEnum, TenantStateEnum } from "@/pages/TenantManagementPage/TenantManagement.type";
import { sliceResetFns } from "..";

export type TenantDetailSlice = {
  tenantInfo: {
    tenant_id: string;
    tenant_name: string;
    tenant_ownerid: string;
    command_exec_enabled: string;
    plan: TenantPlanEnum;
    tenant_state: TenantStateEnum;
    is_examination_done: string;
    trial_start_date: number;
    trial_end_date: number;
    contract_application_date: number;
    contract_start_date: number;
    contract_end_date: number;
  };
  billingInfo: {
    is_corporation?: boolean;
    company_name: string;
    department: string;
    position: string;
    surname: string;
    surname_ruby: string;
    name: string;
    name_ruby: string;
    post_code: string;
    state: string;
    address_line1: string;
    address_line2: string;
    mail_address: string;
    phone_number: string;
  };
  setTenantInfoData: (data) => void;
  setBillingInfoData: (data) => void;
  resetBillingInfo: () => void;
};

export const initialState = {
  tenantInfo: {
    tenant_id: "",
    tenant_name: "",
    tenant_ownerid: "",
    command_exec_enabled: "",
    plan: TenantPlanEnum.TRIAL,
    tenant_state: TenantStateEnum.EXAMINATION,
    is_examination_done: "",
    trial_start_date: 0,
    trial_end_date: 0,
    contract_application_date: 0,
    contract_start_date: 0,
    contract_end_date: 0,
  },
  billingInfo: {
    company_name: "",
    department: "",
    position: "",
    surname: "",
    surname_ruby: "",
    name: "",
    name_ruby: "",
    post_code: "",
    state: "",
    address_line1: "",
    address_line2: "",
    mail_address: "",
    phone_number: "",
  },
};

export const tenantDetailSlice: StateCreator<TenantDetailSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setTenantInfoData: (data) => {
      set(() => ({ tenantInfo: data }));
    },
    setBillingInfoData: (data) => {
      set(() => ({ billingInfo: data }));
    },
    resetBillingInfo: () => {
      set({ billingInfo: initialState.billingInfo });
    },
  };
};
