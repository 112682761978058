import { Alert, Button, CheckboxField, Link, PasswordField, TextField } from "@aws-amplify/ui-react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { LOGIN } from "../queries";
import yup from "../../../locales/validation";
import { IStageProps, STAGE } from "../LoginPage.type";
import useBoundStore from "../../../stores";
import CustomErrorMessage from "@/components/ui/CustomErrorMessage/CustomErrorMessage";
import { encryptData } from "@/utils/helper";

// mutation for updating user's information
const CHANGE_PASSWORD = gql`
  mutation ForceChangePassword($input: ForceChangePasswordInput!) {
    forceChangePassword(input: $input)
  }
`;

//schema for validation
const schema = yup.object({
  username: yup
    .string()
    .required("CommonError.FieldRequired")
    .matches(/^(?!\s*$).+/, "CommonError.FieldRequired"),
  new_password: yup
    .string()
    .required("CommonError.FieldRequired")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\])[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\A-Za-z\d]{8,99}$/,
      "CommonError.PasswordInvalid",
    ),
  confirmPassword: yup
    .string()
    .test("password-match", "CommonError.ConfirmPasswordNotMatch", function (value, context) {
      if (!value) {
        return this.createError({ message: "CommonError.FieldRequired" });
      }
      if (value !== context.parent.new_password) {
        return this.createError({ message: "CommonError.ConfirmPasswordNotMatch" });
      }
      return true;
    }),
  email: yup.string().email().required(),
});

const ChangePassword = ({ setStage }: IStageProps) => {
  // Checkbox custom validation
  const [accept, setAccept] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [email, setUsernameLocalStorage, setEmailLocalStorage, setLanguageLocalStorage] = useBoundStore((state) => [
    state.email,
    state.setUsername,
    state.setEmail,
    state.setLanguage,
  ]);

  const { t } = useTranslation();
  const { authenUser } = useAuth();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    watch,
    trigger,
    getValues,
    getFieldState,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const [changePassword, { error }] = useMutation(CHANGE_PASSWORD);
  const [login, { error: loginError }] = useMutation(LOGIN);

  async function onChangePasswordSubmit(data) {
    try {
      setLoading(true);
      await changePassword({
        variables: {
          input: {
            username: data.username,
            new_password: data.new_password,
            email: data.email,
          },
        },
      });
      // After updating user's information, sign the user in with newly created password.
      const res = await login({
        variables: {
          payload: {
            email: data.email,
            password: data.new_password,
          },
        },
      });
      localStorage.setItem("atmark-accessToken", res.data.login.token.access_token);
      localStorage.setItem("atmark-refreshToken", res.data.login.token.refresh_token);
      localStorage.setItem(
        "atmark-encryptedUserData",
        encryptData({
          username: res.data.login.username,
          email: res.data.login.email,
          permissions: res.data.login.permissions,
          tenant_id: res.data.login.tenant_id,
          service_mgmt_role: res.data.login.service_mgmt_role,
        }),
      );
      setEmailLocalStorage(res.data.login.email);
      setUsernameLocalStorage(res.data.login.username);
      setLanguageLocalStorage(res.data.login.language);
      authenUser();
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    // Redirect back to login if invalid email is provided
    if (!email) {
      setStage(STAGE.INITIAL);
    }
  }, [email, setStage]);

  return (
    <form
      onSubmit={handleSubmit(onChangePasswordSubmit)}
      className="flex flex-col gap-4 whitespace-pre-line p-8">
      <header className="text-2xl font-semibold">{t("ChangePassword")}</header>
      <input
        {...register("email", { value: email })}
        type="hidden"
      />
      <TextField
        data-testid="change-password-username-form"
        {...register("username")}
        label={t("Username")}
        placeholder={String(t("UsernamePlaceholder"))}
        hasError={Boolean(errors.username)}
        errorMessage={errors.username && String(t(String(errors.username.message), { field: t("Username") }))}
      />
      <div>
        <PasswordField
          data-testid="change-password-form"
          {...register("new_password", {
            onChange: () => {
              if (getValues("confirmPassword")) {
                trigger("confirmPassword");
              }
            },
          })}
          onKeyDown={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
            }
          }}
          label={t("NewPassword")}
          placeholder={String(t("NewPasswordPlaceholder"))}
          hasError={Boolean(errors.new_password)}
          hideShowPassword={true}
        />
        {getFieldState("new_password").isDirty && (
          <CustomErrorMessage
            className="mt-2"
            data={watch("new_password")}
          />
        )}
      </div>
      <PasswordField
        data-testid="change-password-confirm-form"
        {...register("confirmPassword")}
        onCopy={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}
        onKeyDown={(e) => {
          if (e.code === "Space") {
            e.preventDefault();
          }
        }}
        label={t("ConfirmPassword")}
        placeholder={String(t("ConfirmPasswordPlaceholder"))}
        hasError={Boolean(errors.confirmPassword)}
        errorMessage={
          errors.confirmPassword && String(t(String(errors.confirmPassword.message), { field: t("ConfirmPassword") }))
        }
        hideShowPassword={true}
      />
      <CheckboxField
        data-testid="test"
        name="acknowledgement"
        checked={accept}
        onChange={(e) => {
          if (e.target.checked) {
            setAccept(true);
            return;
          }
          setAccept(false);
        }}
        label={
          <span data-testid="change-password-checkbox">
          <Trans i18nKey={"P&S"}>
            I accept the
            <Link
              href="https://www.atmark-techno.com/privacy"
              target="_blank">
              Privacy Policy
            </Link>
            and
            <Link
              href="https://download.atmark-techno.com/armadillo-twin/document/armadillo-twin-terms.pdf"
              target="_blank">
              Terms of Service
            </Link>
          </Trans>
          </span>
        }
      />
      {(error || loginError) && (
        <Alert
          variation="error"
          isDismissible>
          {error && error.message === "DuplicatedUsername"
            ? t("User.Error.DuplicatedUsername")
            : t(String(error?.message))}
          {loginError && t(String(loginError?.message))}
        </Alert>
      )}
      <Button
        data-testid="reset-password-button"
        minHeight={"41.6px"}
        isLoading={loading}
        isDisabled={!isValid || !accept || loading}
        type="submit"
        variation="primary">
        {t("ChangePassword")}
      </Button>
      <Button
        width={"fit-content"}
        fontWeight={"400"}
        size="small"
        variation="link"
        margin={"auto"}
        onClick={() => setStage(STAGE.INITIAL)}
        data-testid="return-button">
        {t("ReturnToSignIn")}
      </Button>
    </form>
  );
};

export default ChangePassword;
