import { Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Image } from "@aws-amplify/ui-react";
import AtmarkLogo from "/images/AtmarkTechno_logo2.png";

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <main className="flex h-screen w-screen items-center justify-center">
      <div className="px-4 py-5 text-center">
        <Image
          src={AtmarkLogo}
          alt=""
          width="150px"
          height="198.5px"
          style={{ objectFit: "contain" }}
        />
        <h2 className="text-2xl font-normal tracking-wide">{t("MAINTENANCE_NOTE")}</h2>
        <h2 className="mt-4 text-2xl font-normal tracking-wide text-error-msg">{t("MAINTENANCE_TIME_TEXT")}</h2>
        <h2 className="mb-4 text-2xl font-normal tracking-wide text-error-msg">
          {import.meta.env.VITE_MAINTENANCE_PERIOD}
        </h2>
        <div className="mt-4 text-center text-xs font-normal">{t("Maintenance_note_1")}</div>
        <div className="text-center text-xs font-normal">{t("Maintenance_note_2")}</div>
        <div className="text-center text-xs font-normal">{t("Maintenance_note_3")}</div>
        <div className="mb-4 mt-4 text-center text-xs font-normal">{t("Maintenance_note_4")}</div>
      </div>
    </main>
  );
};

export default Maintenance;
