import { StateCreator } from "zustand";
import { sliceResetFns } from "..";

// List of errors occured when registering devices.
// Feature: Device-Mgmt-03

export type ErrorSlice = {
  error: {
    // Key is a string used for displaying errors,
    // value is an object contains 2 attributes:
    // serverMessage: optional, used when the error is thrown from the server
    // deviceList: list of device id to display
    errorList: Map<
      string,
      {
        serverMessage?: string;
        deviceList: Set<string>;
      }
    >;
  };
  resetError: () => void;
};

const initialState = {
  error: {
    errorList: new Map(),
  },
};

export const createErrorSlice: StateCreator<ErrorSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    resetError: () => set(initialState),
  };
};
