import MainLayout from "@/components/ui/MainLayout/MainLayout";
import RequiredAuth from "@/components/ui/RequiredAuth/RequiredAuth";
import { AuthProvider } from "@/hooks/useAuth";
import LoginPage from "@/pages/AuthenticationPage/LoginPage";
import Forbidden from "@/pages/ErrorPage/Forbidden";
import Maintenance from "@/pages/ErrorPage/Maintenance";
import NotFound from "@/pages/ErrorPage/NotFound";
import SignUp from "@/pages/SignUpPage/SignUp";
import SupportContact from "@/pages/SupportContact/SupportContact";
import { Loader } from "@aws-amplify/ui-react";
import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate } from "react-router";
import { BrowserRouter } from "react-router-dom";

const DashboardPage = lazy(() => import("@/pages/DashboardPage/DashboardPage"));
const UserListPage = lazy(() => import("@/pages/UserListPage/UserList"));
const UserDetailPage = lazy(() => import("@/pages/UserDetailPage/UserDetail"));
const CreateNoticePage = lazy(() => import("@/pages/CreateNoticePage/CreateNoticePage"));
const DeviceManagementPage = lazy(() => import("@/pages/DeviceManagementPage/DeviceManagement"));
const DeviceDetailPage = lazy(() => import("@/pages/DeviceDetailPage/DeviceDetail"));
const DeviceCreatePage = lazy(() => import("@/pages/DeviceCreatePage/DeviceCreate"));
const DeviceGroupManagementPage = lazy(() => import("@/pages/DeviceGroupManagementPage/DeviceGroupManagement"));
const DeviceGroupCreatePage = lazy(() => import("@/pages/DeviceGroupCreatePage/DeviceGroupCreate"));
const DeviceGroupDetailPage = lazy(() => import("@/pages/DeviceGroupDetailPage/DeviceGroupDetail"));
const NotificationListPage = lazy(() => import("@/pages/NotificationListPage/NotificationList"));
const UserSettingsPage = lazy(() => import("@/pages/UserSettingsPage/UserSettings"));
const TenantSettingsPage = lazy(() => import("@/pages/TenantSettingsPage/TenantSettings"));
const TenantManagementPage = lazy(() => import("@/pages/TenantManagementPage/TenantManagement"));
const TenantDetailPage = lazy(() => import("@/pages/TenantDetailPage/TenantDetailPage"));
const UserManagementPage = lazy(() => import("@/pages/UserManagementPage/UserManagement"));
const UserMgmtDetailPage = lazy(() => import("@/pages/UserMgmtDetailPage/UserMgmtDetail"));
const UsageBillingPage = lazy(() => import("@/pages/UsageBillingManagementPage/UsageBillingManagement"));
const AdminUserManagementPage = lazy(() => import("@/pages/AdminUserPage/AdminUserManagement"));
const DeviceRegistrationStatusPage = lazy(
  () => import("@/pages/DeviceRegistrationStatusPage/DeviceRegistrationStatus"),
);
const ExecuteArbitraryCommand = lazy(() => import("@/pages/ExecuteArbitraryCommandPage/ExecuteArbitraryCommand"));
const ExecuteFirmwareUpdate = lazy(() => import("@/pages/ExecuteFirmwareUpdatePage/ExecuteFirmwareUpdate"));

const SuspenseFallback = () => <Loader size="large" />;

const AppRoutes = () => {
  const { t } = useTranslation();

  /**
   * https://vitejs.dev/guide/build#load-error-handling
   * - potential fix for "Failed to fetch dynamically imported module "
   */
  window.addEventListener("vite:preloadError", (event) => {
    console.error(event);
    window.location.reload(); // for example, refresh the page
  });

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={<Navigate to={`${t("lang")}`} />}
          />
          <Route
            path={`/:lang`}
            element={
              <RequiredAuth>
                <MainLayout />
              </RequiredAuth>
            }>
            <Route
              index
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DashboardPage />
                </Suspense>
              }></Route>
            <Route
              path="users"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UserListPage />
                </Suspense>
              }></Route>
            <Route
              path="users/:id"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UserDetailPage />
                </Suspense>
              }></Route>
            <Route
              path="create-notification"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <CreateNoticePage />
                </Suspense>
              }></Route>
            <Route
              path="devices"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceManagementPage />
                </Suspense>
              }></Route>
            <Route
              path="devices/:id"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceDetailPage />
                </Suspense>
              }></Route>
            <Route
              path={"devices/create"}
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceCreatePage />
                </Suspense>
              }></Route>
            <Route
              path="devices/create/device-registration-status"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceRegistrationStatusPage />
                </Suspense>
              }
            />
            <Route
              path="device-group"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceGroupManagementPage />
                </Suspense>
              }></Route>
            <Route
              path="device-group/create"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceGroupCreatePage />
                </Suspense>
              }></Route>
            <Route
              path="device-group/:id"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <DeviceGroupDetailPage />
                </Suspense>
              }></Route>
            <Route
              path="notifications"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <NotificationListPage />
                </Suspense>
              }></Route>
            <Route
              path="user-settings"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UserSettingsPage />
                </Suspense>
              }></Route>
            <Route
              path="account-settings"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <TenantSettingsPage />
                </Suspense>
              }></Route>
            <Route
              path="accounts"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <TenantManagementPage />
                </Suspense>
              }
            />
            <Route
              path="accounts/:id"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <TenantDetailPage />
                </Suspense>
              }
            />
            <Route
              path="users-mgmt"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UserManagementPage />
                </Suspense>
              }
            />
            <Route
              path="users-mgmt/:id"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UserMgmtDetailPage />
                </Suspense>
              }
            />
            <Route
              path="usage-billing"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <UsageBillingPage />
                </Suspense>
              }></Route>
            <Route
              path="admin-user-management"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminUserManagementPage />
                </Suspense>
              }></Route>
            <Route
              path="support-contact"
              element={<SupportContact />}></Route>
            <Route
              path="execute-arbitrary-command"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <ExecuteArbitraryCommand />
                </Suspense>
              }
            />
            <Route
              path="execute-firmware-update"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <ExecuteFirmwareUpdate />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={<Navigate to="/not-found" />}
            />
          </Route>
          <Route
            path="forbidden"
            element={<Forbidden />}></Route>
          <Route
            path={`/:lang/login`}
            element={<LoginPage />}
          />
          <Route
            path={`/:lang/signup`}
            element={<SignUp />}
          />
          <Route
            path={`/:lang?/inquiry`}
            element={<SupportContact />}
          />
          <Route
            path="not-found"
            element={<NotFound />}
          />
          <Route
            path="maintenance"
            element={<Maintenance />}
          />
          <Route
            path="/*"
            element={<Navigate to={"/not-found"} />}
          />
          <Route
            path="*"
            element={<Navigate to={"/not-found"} />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default AppRoutes;
