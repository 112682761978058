import { StateCreator } from "zustand";
import { decryptData, encryptData } from "../../utils/helper";
import { sliceResetFns } from "..";

export type UsernameSlice = {
  username: string;
  setUsername: (newUsername: string) => void;
};

const key = "atmark-encryptedUserData";

const initialState = {
  username: (function () {
    try {
      const data = JSON.parse(decryptData(localStorage.getItem(key)) ?? "{}");
      return data.username ?? "";
    } catch (error) {
      console.log(error);
      return "";
    }
  })(),
};

export const createUsernameSlice: StateCreator<UsernameSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setUsername: (newUsername: string) => {
      const data = JSON.parse(decryptData(localStorage.getItem(key)) ?? "{}");
      data.username = newUsername;
      const encryptedUserData = encryptData(data);
      localStorage.setItem(key, encryptedUserData);
      set(() => ({ username: newUsername }));
    },
  };
};
