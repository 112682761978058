import { useEffect, useReducer } from "react";
import { Alert, Button, Heading, PasswordField, TextField } from "@aws-amplify/ui-react";
import { IStageProps, STAGE } from "../LoginPage.type";
import { useTranslation } from "react-i18next";
import yup from "@/locales/validation";
import useBoundStore from "@/stores";
import { useMutation } from "@apollo/client";
import { REQUEST_RESET_PASSWORD, RESET_PASSWORD } from "../queries";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTimer } from "@/hooks/useTimer";
import CustomErrorMessage from "@/components/ui/CustomErrorMessage/CustomErrorMessage";
import { ILimitState, ILimitAction } from "@/components/shared/interfaces/IOtpLimit.interface";

const INITIAL_TIME = 60;

const ForgetPasswordStage2 = ({ setStage }: IStageProps) => {
  const { t } = useTranslation();

  const [email, encrypted_key, resetEncryptedKey, setEncryptedKey] = useBoundStore((state) => [
    state.email,
    state.encryptedKey,
    state.resetEncryptedKeySlice,
    state.setEncryptedKey,
  ]);

  const [timer, initTimer] = useTimer(INITIAL_TIME);

  const schema = yup.object({
    email: yup.string().required().default(email),
    encrypted_data: yup.string().required().default(encrypted_key),
    new_password: yup
      .string()
      .required("CommonError.FieldRequired")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\])[><^$*.[\]{}()?\-"!@#%&,':;|_~`+=/\\A-Za-z\d]{8,99}$/,
        "CommonError.PasswordInvalid",
      ),
    confirmPassword: yup
      .string()
      .test("password-match", "CommonError.ConfirmPasswordNotMatch", function (value, context) {
        if (!value) {
          return context.createError({ message: "CommonError.FieldRequired" });
        }
        if (value !== context.parent.new_password) {
          return context.createError({ message: "CommonError.ConfirmPasswordNotMatch" });
        }
        return true;
      }),
    otp: yup.string().trim().required("CommonError.FieldRequired"),
  });

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    getValues,
    getFieldState,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const initialLimitState: ILimitState = {
    shouldDisableSubmit: false,
    errorMsg: "",
    errorCount: 0,
  };

  const limitReducer = (state: ILimitState, action: ILimitAction): ILimitState => {
    switch (action.type) {
      case "set_error_count":
        return {
          ...state,
          errorCount: action.payload?.errorCount ?? state.errorCount,
        };
      case "set_error_msg":
        return {
          ...state,
          errorMsg: action.payload?.errorMsg ?? state.errorMsg,
        };
      case "set_should_disable_submit":
        return {
          ...state,
          shouldDisableSubmit: action.payload?.shouldDisableSubmit ?? state.shouldDisableSubmit,
        };
      default:
        return state;
    }
  };

  // a reducer for locking the submit button if the user entered the wrong OTP for 5 times.
  const [limitState, limitDispatch] = useReducer(limitReducer, initialLimitState);

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [requestResetPassword, { loading: resendOtpLoading }] = useMutation(REQUEST_RESET_PASSWORD, {
    onError(error) {
      limitDispatch({
        type: "set_error_msg",
        payload: {
          errorMsg: error.message === "UserNotConfirmed" ? t("User.Error.UserNotConfirmed") : t(error.message),
        },
      });
    },
  });

  const onFormSubmit = async (data) => {
    try {
      delete data.confirmPassword;
      await resetPassword({
        variables: {
          input: data,
        },
      });
      resetEncryptedKey();
      setStage(STAGE.INITIAL);
    } catch (error) {
      console.error(error);
      limitDispatch({ type: "set_error_count", payload: { errorCount: limitState.errorCount + 1 } });
      limitDispatch({
        type: "set_error_msg",
        payload: {
          errorMsg:
            (error as any).message === "OTP mismatch"
              ? t("User.Error.OtpMismatch")
              : (error as any).message ?? String(error),
        },
      });
    }
  };

  const onResendOtpBtnClick = async () => {
    if (!email) {
      return;
    }
    try {
      const response = await requestResetPassword({
        variables: {
          email: email,
        },
      });
      // update secret key with new value
      const encryptedKey = response.data.requestResetPassword;
      setEncryptedKey(encryptedKey);
      // init countdown
      initTimer();
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: "" } });
      limitDispatch({ type: "set_error_count", payload: { errorCount: 0 } });
      limitDispatch({ type: "set_should_disable_submit", payload: { shouldDisableSubmit: false } });
    } catch (error) {
      console.error(error);
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: (error as any).message ?? String(error) } });
    }
  };

  useEffect(() => {
    if (limitState.errorCount === 5) {
      limitDispatch({ type: "set_error_count", payload: { errorCount: 0 } });
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: t("User.Error.OtpExceedLimit") } });
      limitDispatch({ type: "set_should_disable_submit", payload: { shouldDisableSubmit: true } });
    }
  }, [limitState, t]);

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="flex flex-col gap-4 whitespace-pre-line p-8">
      <Heading level={3}>{t("ResetPasswordHeader")}</Heading>
      <TextField
        {...register("otp")}
        isDisabled={limitState.shouldDisableSubmit}
        label={t("User.OtpCodeHeader")}
        placeholder={String(t("User.OtpCodePlaceholder"))}
        hasError={Boolean(errors.otp)}
        errorMessage={errors.otp && String(t(String(errors.otp.message), { field: t("User.OtpCodeHeader") }))}
        data-testid="verification-code-input"
      />
      <div>
        <PasswordField
          {...register("new_password", {
            onChange: () => {
              if (!getValues("confirmPassword")) {
                return;
              }
              trigger("confirmPassword");
            },
          })}
          onKeyDown={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
            }
          }}
          label={t("NewPassword")}
          placeholder={String(t("NewPasswordPlaceholder"))}
          hasError={Boolean(errors.new_password)}
          hideShowPassword={true}
          data-testid="new-password-input"
        />
        {getFieldState("new_password").isDirty && (
          <CustomErrorMessage
            className="mt-2"
            data={watch("new_password")}
          />
        )}
      </div>

      <PasswordField
        {...register("confirmPassword")}
        onCopy={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}
        onKeyDown={(e) => {
          if (e.code === "Space") {
            e.preventDefault();
          }
        }}
        label={t("ConfirmPassword")}
        placeholder={String(t("ConfirmPasswordPlaceholder"))}
        hasError={Boolean(errors.confirmPassword)}
        errorMessage={
          errors.confirmPassword && String(t(String(errors.confirmPassword.message), { field: t("ConfirmPassword") }))
        }
        hideShowPassword={true}
        data-testid="confirm-password-input"
      />
      {limitState.errorMsg && (
        <Alert
          variation="error"
          isDismissible
          data-testid="error-message">
          {limitState.errorMsg}
        </Alert>
      )}
      <Button
        minHeight={"41.6px"}
        type="submit"
        isDisabled={!isValid || loading || resendOtpLoading || limitState.shouldDisableSubmit}
        isLoading={loading}
        variation="primary"
        data-testid="confirm-button">
        {t("Confirm")}
      </Button>
      {timer === INITIAL_TIME ? (
        <Button
          minHeight={"34.6px"}
          width={"fit-content"}
          margin={"auto"}
          isLoading={resendOtpLoading}
          isDisabled={resendOtpLoading || loading}
          size="small"
          onClick={onResendOtpBtnClick}
          variation="link"
          data-testid="resend-code-button">
          {t("User.ResendCode")}
        </Button>
      ) : (
        <Button
          width={"fit-content"}
          margin={"auto"}
          isDisabled
          size="small"
          variation="link"
          data-testid="countdown-message">
          {t("User.Message.CountdownMsg", { countdown: timer })}
        </Button>
      )}
    </form>
  );
};

export default ForgetPasswordStage2;
