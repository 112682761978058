import AmplifyCheckmark from "@/components/ui/Icon/AmplifyCheckmark"; // import component amplify checkmark
import { useAuth } from "@/hooks/useAuth"; // import hook useAuth
import { useMutation } from "@apollo/client"; // import hook useMutation
import { Alert, Button, Card, SelectField, TextAreaField, TextField } from "@aws-amplify/ui-react"; // import components aws amplify ui react
import { yupResolver } from "@hookform/resolvers/yup"; // import hook yup resolver
import { useForm } from "react-hook-form"; // import hook use form
import { useTranslation } from "react-i18next"; // import hook useTranslation
import { useLocation, useNavigate } from "react-router-dom"; // import hook useNavigate
import { SEND_MAIL_SUPPORT_CONTACT } from "./SupportContact.type"; // import mutation send mail support contact
import useBoundStore from "@/stores"; // import hook useBoundStore
import { useState } from "react"; // import hook useState
import { useToken } from "@/hooks/useToken"; // import hook useToken
import ReCAPTCHA from "react-google-recaptcha"; // import component reCAPTCHA
import { MAX_BYTE_512, MAX_BYTE_EMAIL, MAX_BYTE_MESSAGE, MAX_BYTE_PHONE_NUMBER, schema } from "./SupportContact.chema"; // import constants for validation

/**
 * Implementation support contact component
 * @returns JSX
 */
function SupportContact() {
  let captcha;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { authed, logout } = useAuth();
  const { tenant_id } = useToken();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [isCheckRecapcha, setIsCheckRecapcha] = useState(true);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [setAlert] = useBoundStore((state) => [state.setAlert]);
  const DropDownMenu = [
    { labelKey: "DropDown.PleaseSelectTheContentOfYourInquiry", value: "" },
    { labelKey: "DropDown.RequestAQuote", value: "1" },
    { labelKey: "DropDown.InquiriesRegardingServices", value: "2" },
    { labelKey: "DropDown.OtherInquiries", value: "3" },
  ];
  const [sendMailSupportContact, { loading, error }] = useMutation(SEND_MAIL_SUPPORT_CONTACT);

  /**
   * set captcha ref from reCAPTCHA
   * @param ref ref captcha component from reCAPTCHA
   * @returns
   */
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  /**
   * reset captcha
   */
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    if (captcha) {
      captcha.reset();
    }
  };

  /**
   * check field is required
   * @returns boolean
   */
  const checkFieldIsRequired = () => {
    return [
      getValues("type")?.length,
      getValues("email")?.length,
      getValues("inquiry")?.length,
      getValues("name")?.length,
    ].every((item) => item == undefined || item == 0);
  };

  /**
   * get translation message for support contact
   * @param key message locale config key
   * @param alias alias for translation message config key
   * @returns translation message
   */
  const translation = (key, alias = "SupportContact.") => {
    return t(`${alias + key}`);
  };

  /**
   * on submit send mail data to BE
   */
  const onSubmit = async () => {
    if (message) {
      setMessage(() => "");
    }

    // Handle form submission with validated data
    const response = await sendMailSupportContact({
      variables: {
        payload: {
          type: getValues("type"),
          name: getValues("name"),
          company_name: getValues("companyName"),
          email: getValues("email"),
          phone_number: getValues("phoneNumber"),
          inquiry: getValues("inquiry"),
          job_title: getValues("jobTitle"),
          tenant_id: tenant_id ?? "",
        },
      },
    });
    if (
      response &&
      response?.data?.sendEmailSupportContact?.isRemoveToken === true &&
      pathname.includes("/support-contact")
    ) {
      //BE will check accessToken if it is expired, clear local storage return login.
      await logout();
      return;
    } else if (
      response &&
      response?.data?.sendEmailSupportContact?.isRemoveToken === true &&
      pathname.includes("/inquiry")
    ) {
      const excludedKeys = [
        "atmark-sidebarCollapsed",
        "atmark-defaultFilterObject",
        "atmark-lng",
        "atmark-lng_display",
      ];
      Object.keys(localStorage).forEach((key) => {
        if (!excludedKeys.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    }
    //Set message success
    setMessage(() => translation("MessageSuccess"));
    if (message) {
      setAlert({
        type: "success",
        message,
      });
    }
    //reset all field if send email success
    reset();
    setValue("acceptToPolicy", false);
    resetCaptcha();
    setIsCheckRecapcha(true);
    const container = document.getElementById("content-container");
    container?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  };

  /**
   * on change reCAPTCHA value to make form submission
   * @param value value from reCAPTCHA
   */
  function onChangeRecapcha(value) {
    if (!value) {
      setIsCheckRecapcha(() => true);
    } else {
      // make form submission
      setIsCheckRecapcha(() => false);
    }
  }

  return (
    <div className={`${!authed && "p-4"} mx-auto flex w-full max-w-6xl flex-col gap-4`}>
      {message && (
        <Alert
          variation="success"
          isDismissible
          onDismiss={() => setMessage("")}>
          {t(message)}
        </Alert>
      )}
      <Card
        variation="outlined"
        width={"100%"}>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}>
          {/* SECTION */}
          <h6 className="text-lg font-medium">{translation("CategoryOfInquiry")}</h6>
          <SelectField
            className="!relative"
            {...register("type")}
            label=""
            labelHidden
            width={"100%"}>
            {DropDownMenu.map((item, idx) => (
              <option
                value={item.value}
                key={"mgmt-role" + String(idx)}>
                {translation(item.labelKey)}
              </option>
            ))}
          </SelectField>

          {/* SECTION */}
          <h6 className="text-lg font-medium">{translation("CustomerInformation")}</h6>

          <TextField
            label={
              <p>
                {translation("Name") + " "}
                <span className="font-medium text-error-msg">{translation("Required")}</span>
              </p>
            }
            {...register("name")}
            hasError={Boolean(errors.name)}
            errorMessage={
              errors.name &&
              String(t(String(errors.name.message), { field: translation("Name"), length: MAX_BYTE_512 }))
            }
          />

          <TextField
            label={<p>{translation("CompanyName")}</p>}
            {...register("companyName")}
            hasError={Boolean(errors.companyName)}
            errorMessage={
              errors.companyName &&
              String(t(String(errors.companyName.message), { field: translation("CompanyName"), length: MAX_BYTE_512 }))
            }
          />

          <TextField
            label={<p>{translation("JobTitle")}</p>}
            {...register("jobTitle")}
            hasError={Boolean(errors.jobTitle)}
            errorMessage={
              errors.jobTitle &&
              String(t(String(errors.jobTitle.message), { field: translation("JobTitle"), length: MAX_BYTE_512 }))
            }
          />

          <TextField
            label={
              <p>
                {translation("EmailAddress") + " "}
                <span className="font-medium text-error-msg">{translation("Required")}</span>
              </p>
            }
            {...register("email")}
            hasError={Boolean(errors.email)}
            errorMessage={
              errors.email &&
              String(t(String(errors.email.message), { field: translation("EmailAddress"), length: MAX_BYTE_EMAIL }))
            }
          />

          <TextField
            label={<p>{translation("PhoneNumber")}</p>}
            {...register("phoneNumber", {
              onChange(e) {
                e.target.value = String(e.target.value)
                  .replace(/[^\d-]+/g, "") // Remove any characters that are not digits or hyphens
                  .replace(/^-+/g, "") // Remove leading hyphens
                  .replace(/-{2,}/g, "-"); // Replace consecutive hyphens with a single hyphen
              },
            })}
            hasError={Boolean(errors.phoneNumber)}
            errorMessage={
              errors.phoneNumber &&
              String(
                t(String(errors.phoneNumber.message), {
                  field: translation("PhoneNumber"),
                  length: MAX_BYTE_PHONE_NUMBER,
                }),
              )
            }
          />

          <TextAreaField
            label={
              <h6 className="font-semibold">
                {translation("Inquiry") + " "} <span className=" text-error-msg">{translation("Required")}</span>
              </h6>
            }
            // resize="vertical"
            rows={6}
            {...register("inquiry")}
            hasError={Boolean(errors.inquiry)}
            className="!break-words"
            errorMessage={
              errors.inquiry &&
              String(t(String(errors.inquiry.message), { field: translation("Inquiry"), length: MAX_BYTE_MESSAGE }))
            }
          />
          <div className="flex items-center gap-3">
            <div className="custom-checkbox relative h-4 w-4 items-center overflow-hidden rounded-[20%] border-2">
              <input
                id="acceptToS"
                className="absolute -left-2.5 -top-2.5 z-[2] h-8 w-8 cursor-pointer items-center opacity-0  "
                type="checkbox"
                {...register("acceptToPolicy")}
              />
              <span className="checkmark absolute left-0 top-0 flex h-full w-full items-center justify-center opacity-0">
                <AmplifyCheckmark />
              </span>
            </div>
            <div className="mb-1 pt-2">
              <p className="font-medium">
                <div className="whitespace-pre-line ">{translation("PrivacyPolicy")} </div>
              </p>
            </div>
          </div>
          {error && (
            <div className="font-medium text-error-msg">
              <p>{translation("MessageError")}</p>
              <p>{t(error?.message || "")}</p>
            </div>
          )}
          <div className="font-medium">
            <p className="">{translation("PolicyAndNote.p1")}</p>
            <p className="">
              {translation("PolicyAndNote.p2.p2-1")}
              <a
                className="text-primary hover:text-primary-hover"
                href="https://www.atmark-techno.com/privacy"
                target="_blank">
                <u>{translation("PolicyAndNote.p2.link")}</u>
              </a>

              {translation("PolicyAndNote.p2.p2-2")}
              <a
                className=" text-primary hover:text-primary-hover"
                href="https://www.atmark-techno.com/privacy"
                target="_blank">
                <u>{translation("PolicyAndNote.p2.link")}</u>
              </a>
              {translation("PolicyAndNote.p2.p2-3")}
            </p>
            <p className="">{translation("PolicyAndNote.p3")}</p>
            <p className="mb-">{translation("PolicyAndNote.p4")}</p>
            <p className="mb-4">{translation("PolicyAndNote.p5")}</p>
          </div>
          {!authed ? (
            <div>
              <div className="ml-3">
                <ReCAPTCHA
                  ref={(r) => setCaptchaRef(r)}
                  sitekey={String(import.meta.env.VITE_REACT_APP_SITE_KEY)}
                  onChange={onChangeRecapcha}
                />
              </div>
            </div>
          ) : null}
          <div className="flex gap-4">
            {!authed && (
              <Button
                onClick={() => {
                  navigate("/login");
                }}>
                {t("Back")}
              </Button>
            )}
            <Button
              // If watch("type") == "", return true
              // If form not valid, return true
              // If not tick the checkbox for accepting the policy, return true
              // CheckRecapcha not login yet
              isDisabled={
                checkFieldIsRequired() ||
                !watch("acceptToPolicy") ||
                !watch("type") ||
                !isValid ||
                loading ||
                (!authed && isCheckRecapcha)
              }
              type="submit">
              {t("Send")}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default SupportContact;
