import * as yup from "yup";

// yup configure for validation
yup.setLocale({
  mixed: {
    required: () => ({ key: "CommonError.Required" }),
  },
});

export default yup;
