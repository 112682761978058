import { AlertVariations } from "@aws-amplify/ui-react";
import { StateCreator } from "zustand";
import { sliceResetFns } from "..";

// Alert mostly used to display an alert to indicate whether the action is success or failure, this state is used in many components.

export type AlertSlice = {
  alert: {
    type: AlertVariations;
    message: string;
  };
  setAlert: (newAlert: { type: AlertVariations; message: string }) => void;
  resetAlert: () => void;
};

const initialState = {
  alert: { type: "success" as AlertVariations, message: "" },
};

export const createAlertSlice: StateCreator<AlertSlice> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setAlert: (newAlert) => {
      set(() => ({ alert: newAlert }));
    },
    resetAlert: () => set(initialState),
  };
};
