export enum ErrorCode {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  TENANT_IS_DISABLED = "TENANT_IS_DISABLED",
  EMAIL_IS_NOT_VERIFIED = "EMAIL_IS_NOT_VERIFIED",
  DEVICE_IS_NOT_IN_TENANT = "DEVICE_IS_NOT_IN_TENANT",
  DEVICE_GROUP_IS_NOT_IN_TENANT = "DEVICE_GROUP_IS_NOT_IN_TENANT",
  USER_IS_NOT_IN_TENANT = "USER_IS_NOT_IN_TENANT",
  DATA_DOES_NOT_EXIST = "DATA_DOES_NOT_EXIST",
  DEVICE_DOES_NOT_AVAILABLE = "DEVICE_DOES_NOT_AVAILABLE",
  USER_IS_DISABLED = "USER_IS_DISABLED",
  DEVICE_GROUP_NAME_EXISTS = "Device group name already exists",
  DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
  COMMAND_EXEC_NOT_ENABLED = "command_exec_enabledNotTrue",
  DEVICE_DOES_NOT_EXIST = "DEVICE_DOES_NOT_EXIST",
  DEVICES_IN_GROUP_CANNOT_EXEC_COMMAND = "DEVICES_IN_GROUP_CANNOT_EXEC_COMMAND",
  DEVICE_STANDBY_PLAN = "DEVICE_STANDBY_PLAN",
}
