import { Children, Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useRef } from "react";
import "./Menu.style.scss";
import { createPortal } from "react-dom";
import { Button } from "@aws-amplify/ui-react";
import HamburgerIcon from "../Icon/HamburgerIcon";

interface IMenu {
  /** Custom trigger element, by default it will render a button with menu icon. */
  trigger?: ReactNode;
  /** Control the position of the menu list */
  menuAlign?: "start" | "center" | "end";
  children?: ReactElement[] | ReactElement;
  /** Control the state of the Menu */
  open: boolean;
  /** Handle the state changes */
  setOpen: Dispatch<SetStateAction<boolean>>;
  /** Maximum number of record in menu list */
  maxNumItems?: number;
}

const Menu = ({ trigger, children, menuAlign, open, setOpen, maxNumItems, ...props }: IMenu) => {
  // Ref for the trigger element.
  const triggerRef = useRef<HTMLDivElement>(null);
  // Ref for the menu list.
  const menuListRef = useRef<HTMLDivElement>(null);

  const childrenArr = Children.toArray(children);

  const toggleMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // set the position of the menu list whenever the open state changes.
    const triggerPosition = triggerRef.current?.getBoundingClientRect();
    if (!menuListRef.current || !triggerPosition) {
      return;
    }

    // The menu height is determined by multiplying the height of one item
    // by either the total number of items or the maximum number of items
    // the user wants to display whichever is smaller.
    let menuHeight =
      ((menuListRef.current.scrollHeight + 1.6) / childrenArr.length) *
      Math.min(childrenArr.length, maxNumItems ?? Infinity);

    // edge case when menuHeight exceed the viewport height on both sides.
    if (
      menuHeight > triggerPosition.top &&
      menuHeight > document.documentElement.clientHeight - triggerPosition.bottom
    ) {
      menuHeight = Math.max(
        triggerPosition.top - 16,
        document.documentElement.clientHeight - triggerPosition.bottom - 16,
      );
    }

    menuListRef.current.style.height = menuHeight + "px";

    // setting the vertical position of the menu.
    if (menuHeight > document.documentElement.clientHeight - triggerPosition.bottom) {
      menuListRef.current.style.top = Math.round(triggerPosition.top - menuHeight - 8) + "px";
    } else {
      menuListRef.current.style.top = Math.round(triggerPosition.top + triggerPosition.height + 8) + "px";
    }

    // setting the horizontal position of the menu.
    switch (menuAlign) {
      case "start":
        menuListRef.current.style.left = Math.round(triggerPosition.left - 1) + "px";
        break;
      case "center":
        menuListRef.current.style.left =
          Math.round(triggerPosition.left - (menuListRef.current.scrollWidth - triggerPosition.width) / 2) + "px";
        break;
      case "end":
        menuListRef.current.style.left = Math.round(triggerPosition.right - menuListRef.current.scrollWidth) + "px";
        break;
      default:
        menuListRef.current.style.left = Math.round(triggerPosition.left - 1) + "px";
    }
  }, [open, menuAlign, childrenArr, maxNumItems]);

  return (
    <>
      <div
        className="inline-flex items-center justify-center"
        ref={triggerRef}
        onKeyDown={() => {
          return;
        }}
        onClick={toggleMenu}>
        {trigger ?? (
          <Button>
            <HamburgerIcon />
          </Button>
        )}
      </div>
      {open &&
        createPortal(
          <div className="fixed left-0 top-0 z-50 h-screen w-screen">
            <button
              className="absolute -z-10 h-full w-full"
              onClick={toggleMenu}
            />
            <div
              id="menu-list"
              data-transform-origin={menuAlign ?? "start"}
              ref={menuListRef}
              style={{
                animation: `${open ? "slideIn" : "slideOut"} 150ms`,
              }}
              className="relative flex min-w-[14rem] max-w-fit flex-col overflow-auto rounded-lg border border-font-disabled bg-white shadow-menu"
              {...props}
              data-testid="menu-list">
              {childrenArr.map((child) => child)}
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default Menu;
