import { Alert, Button, Card, Link, Loader, TextField } from "@aws-amplify/ui-react";
import { IStepProps } from "../SignUp.type";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useBoundStore from "@/stores";
import yup from "@/locales/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTimer } from "@/hooks/useTimer";
import { useMutation } from "@apollo/client";
import { RESEND_OTP, ROLLBACK_REIGSTER, VERIFY_USER } from "../queries";
import { useLocation } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { ILimitState, ILimitAction } from "@/components/shared/interfaces/IOtpLimit.interface";

const INITIAL_TIME = 60;

const Step4 = ({ setActiveStep }: IStepProps) => {
  const schema = yup.object({
    otp: yup.string().required("CommonError.FieldRequired"),
  });

  const [contractorData, verificationData, setVerificationData] = useBoundStore((state) => [
    state.contractorData,
    state.verificationData,
    state.setVerificationData,
  ]);

  const { state } = useLocation();

  const { t } = useTranslation();

  const [timer, initTimer] = useTimer(INITIAL_TIME);

  const [verifyUser, { loading }] = useMutation(VERIFY_USER);
  const [resendOtp, { loading: resendLoading }] = useMutation(RESEND_OTP);
  const [rollbackRegistration, { loading: rollbackLoading }] = useMutation(ROLLBACK_REIGSTER, {
    variables: {
      input: {
        user_id: verificationData.user_id,
      },
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const initialLimitState: ILimitState = {
    shouldDisableSubmit: false,
    errorMsg: "",
    errorCount: 0,
  };

  const limitReducer = (state: ILimitState, action: ILimitAction): ILimitState => {
    switch (action.type) {
      case "set_error_count":
        return {
          ...state,
          errorCount: action.payload?.errorCount ?? state.errorCount,
        };
      case "set_error_msg":
        return {
          ...state,
          errorMsg: action.payload?.errorMsg ?? state.errorMsg,
        };
      case "set_should_disable_submit":
        return {
          ...state,
          shouldDisableSubmit: action.payload?.shouldDisableSubmit ?? state.shouldDisableSubmit,
        };
      default:
        return state;
    }
  };

  // a reducer for locking the submit button if the user entered the wrong OTP for 5 times.
  const [limitState, limitDispatch] = useReducer(limitReducer, initialLimitState);

  const onFormSubmit = async (data) => {
    const input = {
      otp: data.otp,
      user_id: verificationData.user_id,
      verification_token: verificationData.verification_token,
    };
    try {
      await verifyUser({
        variables: {
          input,
        },
      });
      setActiveStep(4);
    } catch (error) {
      console.error(error);
      limitDispatch({ type: "set_error_count", payload: { errorCount: limitState.errorCount + 1 } });
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: (error as any).message ?? String(error) } });
    }
  };

  const onResendOtpLinkClick = async () => {
    try {
      const response = await resendOtp({
        variables: {
          input: {
            user_id: verificationData.user_id,
          },
        },
      });
      setVerificationData({
        ...verificationData,
        verification_token: response.data.sendVerification.verification_token,
      });
      initTimer();
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: "" } });
      limitDispatch({ type: "set_error_count", payload: { errorCount: 0 } });
      limitDispatch({ type: "set_should_disable_submit", payload: { shouldDisableSubmit: false } });
    } catch (error) {
      console.error(error);
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: (error as any).message ?? String(error) } });
    }
  };

  const onGoBackBtnClick = async () => {
    try {
      await rollbackRegistration();
      setActiveStep(1);
    } catch (error) {
      console.error(error);
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: (error as any).message ?? String(error) } });
    }
  };

  useEffect(() => {
    if (limitState.errorCount === 5) {
      limitDispatch({ type: "set_error_count", payload: { errorCount: 0 } });
      limitDispatch({ type: "set_error_msg", payload: { errorMsg: t("User.Error.OtpExceedLimit") } });
      limitDispatch({ type: "set_should_disable_submit", payload: { shouldDisableSubmit: true } });
    }
  }, [limitState, t]);

  return (
    <Card
      variation="outlined"
      overflow={"hidden"}>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="overflow-hidden p-2.5">
        <div className="whitespace-pre-line">
          {t("UserAuth06.Step4.EmailMessage", { email: contractorData.email ? contractorData.email : state?.email })}
        </div>
        <TextField
          {...register("otp")}
          isDisabled={limitState.shouldDisableSubmit}
          className="!my-2"
          maxWidth={"250px"}
          label={t("UserAuth06.Step4.VerificationCode")}
          hasError={Boolean(errors.otp)}
          errorMessage={String(t(`${errors.otp?.message}`, { field: t("User.OtpCodeHeader") }))}
          data-testid="verification-code-input"></TextField>
        <div>{t("UserAuth06.Step4.Reminder")}</div>
        {timer < INITIAL_TIME ? (
          <div className="text-neutral-400">{t("User.Message.CountdownMsg", { countdown: timer })}</div>
        ) : (
          <>
            {resendLoading ? (
              <Loader
                className="!mt-1"
                size="large"
              />
            ) : (
              <Link
                onClick={onResendOtpLinkClick}
                className="!font-medium">
                {t("UserAuth06.Step4.ResendEmail")}
              </Link>
            )}
          </>
        )}
        {limitState.errorMsg && (
          <Alert
            className="!mb-4 !mt-3"
            variation="error"
            isDismissible>
            {t(limitState.errorMsg)}
          </Alert>
        )}
        <div className="mt-5 flex justify-start gap-4">
          {!state?.disablePrevBtn && (
            <Button
              onClick={onGoBackBtnClick}
              isLoading={rollbackLoading}
              isDisabled={loading || resendLoading || rollbackLoading}>
              {t("UserAuth06.Step4.Back")}
            </Button>
          )}
          <Button
            minHeight={"41.6px"}
            isLoading={loading}
            isDisabled={loading || !isValid || resendLoading || rollbackLoading || limitState.shouldDisableSubmit}
            type="submit"
            variation="primary"
            data-testid="confirm-button">
            {t("UserAuth06.Step4.Confirm")}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default Step4;
