import { ReactElement, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useToken } from "@/hooks/useToken";
import { PermissionEnum, StaffRouteEnum, UserRouteEnum } from "@/authorization/constants";
import useBoundStore from "@/stores";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactElement;
}

const RequiredAuth = ({ children }: Props) => {
  const { i18n } = useTranslation();
  const { authed } = useAuth();
  const { permissions, service_mgmt_role } = useToken();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const routePermissionsMap = useBoundStore().routePermissionsMap;

  const pathnameArr = pathname.split("/");
  const lang = pathname.split("/")[1];

  // by default, user will be authorized to access the route.
  let isAuthorized = true;

  useEffect(() => {
    const bc = new BroadcastChannel("general_channel");
    bc.onmessage = (event) => {
      if (event.data === "logout") {
        navigate({
          pathname: `/${i18n.language}/login`,
        });
      }
    };
    return () => {
      bc.close();
    };
  }, []);

  // redirect user back to their chosen language when they try to enter incorrect language key.
  if (lang !== i18n.language) {
    pathnameArr[1] = i18n.language;
    return <Navigate to={pathnameArr.join("/")} />;
  }

  // authentication
  if (!authed) {
    return <Navigate to={`/${i18n.language}/login`} />;
  }

  // route authorization.
  // redirect normal user to error page if they try to access admin routes and vice versa.
  for (const route of Object.values(UserRouteEnum)) {
    if (new RegExp(route).test(pathname) && service_mgmt_role) {
      console.log("admin trying to access user route");
      return <Navigate to={"/forbidden"} />;
    }
  }

  for (const route of Object.values(StaffRouteEnum)) {
    if (new RegExp(route).test(pathname) && !service_mgmt_role) {
      console.log("user trying to access staff route");
      return <Navigate to={"/forbidden"} />;
    }
  }

  // check if users have at least 1 role from the list of roles required to
  // access the route.
  Array.from(routePermissionsMap.keys()).forEach((routeRegex) => {
    if (new RegExp(routeRegex).test(pathname)) {
      const requiredPermissions = routePermissionsMap.get(routeRegex) as PermissionEnum[];
      // if requiredPermissions is empty, this route is a public route and can be accessed by any authenticated users.
      if (requiredPermissions.length > 0) {
        isAuthorized = requiredPermissions.some((val) => permissions?.includes(val));
      }
    }
  });

  if (!isAuthorized) {
    return <Navigate to={"/forbidden"} />;
  }

  return children;
};

export default RequiredAuth;
