import BaseModal from "../../BaseModal/BaseModal";
import { useTranslation } from "react-i18next";
import { Button } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { SharedRouteEnum } from "@/authorization/constants";

const PermissionsUpdateModal = ({ forceRender, closeModal }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onModalClose = () => {
    const currenRoute = window.location.pathname;
    if (new RegExp(SharedRouteEnum.DASHBOARD).test(currenRoute)) {
      forceRender();
    } else {
      navigate(`/${i18n.language}`);
    }
    closeModal();
  };

  return (
    <BaseModal
      closeModal={() => {
        onModalClose();
      }}>
      <div className="w-full max-w-2xl border border-card bg-white">
        <header className="bg-modalheader px-6 py-4 text-xl font-semibold">
          {t("User.Message.PermissionsChangedTitle")}
        </header>
        <div className="whitespace-pre-line px-6 py-4">{t("User.Message.PermissionsChangedDescription")}</div>
        <footer className="flex justify-end border-t px-6 py-4">
          <Button
            variation="primary"
            onClick={onModalClose}>
            {t("Confirm")}
          </Button>
        </footer>
      </div>
    </BaseModal>
  );
};

export default PermissionsUpdateModal;
