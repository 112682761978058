export enum STORAGE_KEYS {
  REFRESH_TOKEN = "atmark-refreshToken",
  ACCESS_TOKEN = "atmark-accessToken",
  USER_DATA = "atmark-encryptedUserData",
}

export const getLocalStorageValue = (key: string) => {
  if (!key) {
    throw new Error("Invalid local storage key");
  }
  return localStorage.getItem(key) ?? "";
};

export const setLocalStorageValue = (key: string, value: string) => {
  if (!key || !value) {
    throw new Error("Invalid local storage key or value");
  }
  localStorage.setItem(key, value);
};
