import { Button, Card } from "@aws-amplify/ui-react";
import { BasicDataEnum, IStepProps } from "../../SignUp.type";
import { useTranslation } from "react-i18next";
import "./Step1.style.scss";
import useBoundStore from "@/stores";

const StyledButton = ({ heading, description, selected, ...props }) => {
  return (
    <button
      className={`${
        selected && "selected"
      } option-select flex flex-1 flex-col items-start whitespace-pre-line rounded-[4px] p-4 text-left font-medium outline outline-1 duration-[250ms] ease-in-out disabled:cursor-not-allowed`}
      {...props}>
      <div className="text-lg">{heading}</div>
      <div className="text-font-disabled">{description}</div>
    </button>
  );
};

const Step1 = ({ setActiveStep }: IStepProps) => {
  const { t } = useTranslation();
  const [basicData, setBasicData] = useBoundStore((state) => [state.basicData, state.setBasicData]);

  const onOptionBtnClick = (key: string, value: string) => {
    const newData = { ...basicData };
    newData[key] = value;

    // Autmatically set the startType depedens on the usagePlan user select.
    if (key === BasicDataEnum.USAGE_PLAN && value !== BasicDataEnum.USAGE_PLAN_CONTRACT) {
      delete newData.startType;
    } else if (key === BasicDataEnum.USAGE_PLAN && value === BasicDataEnum.USAGE_PLAN_CONTRACT) {
      newData.startType = BasicDataEnum.START_TYPE_IMMEDIATE;
    }

    setBasicData(newData);
  };

  return (
    <Card variation="outlined">
      <div className="px-2.5 pb-2.5">
        {/* Usage Plan */}
        <header className="mb-2 text-xl font-semibold leading-loose">{t("UserAuth06.Step1.UsagePlan")}</header>
        <div className="flex gap-4">
          <StyledButton
            selected={basicData.usagePlan === BasicDataEnum.USAGE_PLAN_TRIAL}
            heading={t("UserAuth06.Step1.Trial")}
            description={
              <>
                <div>{t("UserAuth06.Step1.TrialDescription")}</div>
                {/* <div className="text-error-msg">{t("UserAuth06.Step1.TrialDescriptionMaintenance")}</div> */}
              </>
            }
            onClick={() => onOptionBtnClick(BasicDataEnum.USAGE_PLAN, BasicDataEnum.USAGE_PLAN_TRIAL)}
            data-testid="trial-styled-button"
          />
          <StyledButton
            selected={basicData.usagePlan === BasicDataEnum.USAGE_PLAN_CONTRACT}
            heading={t("UserAuth06.Step1.Contract")}
            description={t("UserAuth06.Step1.ContractDescription")}
            onClick={() => onOptionBtnClick(BasicDataEnum.USAGE_PLAN, BasicDataEnum.USAGE_PLAN_CONTRACT)}
            data-testid="contract-styled-button"
          />
        </div>

        {basicData.usagePlan === BasicDataEnum.USAGE_PLAN_CONTRACT && (
          <>
            {/* Usage plan start date */}
            <header className="mb-2 mt-4 text-xl font-semibold leading-loose">
              {t("UserAuth06.Step1.PlanStartDate")}
            </header>
            <div className="flex gap-4">
              <StyledButton
                selected={basicData.startType === BasicDataEnum.START_TYPE_IMMEDIATE}
                heading={t("UserAuth06.Step1.Immediate")}
                description={t("UserAuth06.Step1.ImmediateDescription")}
                onClick={() => onOptionBtnClick(BasicDataEnum.START_TYPE, BasicDataEnum.START_TYPE_IMMEDIATE)}
                data-testid="immediate-styled-button"
              />
              <StyledButton
                selected={basicData.startType === BasicDataEnum.START_TYPE_NEXT_MONTH}
                heading={t("UserAuth06.Step1.NextMonth")}
                description={t("UserAuth06.Step1.NextMonthDescription")}
                onClick={() => onOptionBtnClick(BasicDataEnum.START_TYPE, BasicDataEnum.START_TYPE_NEXT_MONTH)}
                data-testid="nextmonth-styled-button"
              />
            </div>
          </>
        )}

        {/* Contractor type */}
        <header className="mb-2 mt-4 text-xl font-semibold leading-loose">
          {t("UserAuth06.Step1.ContractorInformation")}
        </header>
        <div className="flex gap-4">
          <StyledButton
            selected={basicData.contractorType === BasicDataEnum.CONTRACTOR_TYPE_PERSONAL}
            heading={t("UserAuth06.Step1.Individual")}
            description={t("UserAuth06.Step1.IndividualDescription")}
            onClick={() => onOptionBtnClick(BasicDataEnum.CONTRACTOR_TYPE, BasicDataEnum.CONTRACTOR_TYPE_PERSONAL)}
            data-testid="Individual-styled-button"
          />
          <StyledButton
            selected={basicData.contractorType === BasicDataEnum.CONTRACTOR_TYPE_CORPORATION}
            heading={t("UserAuth06.Step1.Corporation")}
            description={t("UserAuth06.Step1.CorporationDescription")}
            onClick={() => onOptionBtnClick(BasicDataEnum.CONTRACTOR_TYPE, BasicDataEnum.CONTRACTOR_TYPE_CORPORATION)}
            data-testid="corporation-styled-button"
          />
        </div>
        <div className="mt-[26px] flex justify-start">
          <Button
            isDisabled={!basicData.contractorType || !basicData.usagePlan}
            onClick={() => setActiveStep(1)}
            variation="primary"
            data-testid="next-button">
            {t("UserAuth06.Step1.Next")}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Step1;
