import { create as _create } from "zustand";
import type { StateCreator } from "zustand";
import { EmailSlice, createEmailSlice } from "./slices/emailSlice";
import { UsernameSlice, createUsernameSlice } from "./slices/usernameSlice";
import { EncryptedKeySlice, createEncryptedKeySlice } from "./slices/encryptedKeySlice";
import { ErrorSlice, createErrorSlice } from "./slices/errorSlice";
import { AlertSlice, createAlertSlice } from "./slices/alertSlice";
import { LanguageSlice, createLanguageSlice } from "./slices/languageSlice";
import { RoutePermissionsSlice, createRoutePermissionsSlice } from "./slices/routePermissionsSlice";
import { CreateAccountSlice, createAccountSlice } from "./slices/createAccountSlice";
import { TenantDetailSlice, tenantDetailSlice } from "./slices/tenantDetailSlice";
import { UserDetailSlice, userDetailSlice } from "./slices/userDetailSlice";
import { TenantBillingInfoSlice, tenantBillingInfoSlice } from "./slices/tenantBillingInfo";
import { DefaultFilterSlice, createDefaultFilterSlice } from "./slices/defaultFilterSlice";
import { TenantPlanSlice, createTenantPlanSlice } from "./slices/tenantPlanSlice";

export const sliceResetFns = new Set<() => void>();

export const resetAllStores = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const create = (<T>() => {
  return (stateCreator: StateCreator<T>) => {
    const store = _create(stateCreator);
    const initialState = store.getState();
    sliceResetFns.add(() => {
      store.setState(initialState, true);
    });
    return store;
  };
}) as typeof _create;

export const useBoundStore = create<
  UsernameSlice &
    EmailSlice &
    EncryptedKeySlice &
    LanguageSlice &
    ErrorSlice &
    TenantPlanSlice &
    AlertSlice &
    RoutePermissionsSlice &
    CreateAccountSlice &
    TenantDetailSlice &
    UserDetailSlice &
    TenantBillingInfoSlice &
    DefaultFilterSlice
>()((...a) => ({
  ...createUsernameSlice(...a),
  ...createEmailSlice(...a),
  ...createEncryptedKeySlice(...a),
  ...createErrorSlice(...a),
  ...createTenantPlanSlice(...a),
  ...createAlertSlice(...a),
  ...createLanguageSlice(...a),
  ...createRoutePermissionsSlice(...a),
  ...createAccountSlice(...a),
  ...tenantDetailSlice(...a),
  ...userDetailSlice(...a),
  ...userDetailSlice(...a),
  ...tenantBillingInfoSlice(...a),
  ...createDefaultFilterSlice(...a),
}));

export default useBoundStore;
