import { useState, useRef } from "react";

interface ITimer extends Array<number | (() => void)> {
  0: number;
  1: () => void;
}

export const useTimer = (initialValue: number): ITimer => {
  const INITIAL_COUNTER = initialValue;

  const [timer, setTimer] = useState<number>(INITIAL_COUNTER);
  const timerRef = useRef<number>(INITIAL_COUNTER);

  const initTimer = () => {
    setTimer((prev) => prev - 1);
    timerRef.current--;
    const interval = setInterval(() => {
      if (timerRef.current === 0) {
        clearInterval(interval);
        setTimer(INITIAL_COUNTER);
        timerRef.current = INITIAL_COUNTER;
        return;
      }
      setTimer((prev) => prev - 1);
      timerRef.current--;
    }, 1000);
  };

  return [timer, initTimer];
};
